import * as types from '../actions/action-types';

const initialState = {
        clients: []
}

export default (state = initialState, action) => {

  switch (action.type) {

    case types.APPLICATION_ADD_CLIENT:

        // add client if doesn't exist
        state.clients.forEach( (item, index ) => {

            if ( item.name === action.client )
            {
                return state;
            }
        } );
        return Object.assign( {}, state, { clients: [ ...state.clients, { name: action.client } ] } );

    /*
      case types.USER_REPLACE:

           // console.log( "action: ", action );
            return Object.assign({}, state, { user: action.user } );

      case types.USER_UPDATE:

          // console.log( "action: ", action );
          return Object.assign({}, state, { user: Object.assign( state.user, action.fields ) } );

    */

    default:
      return state;
  }
};