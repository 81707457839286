import superagent from 'superagent';
import * as userActions from '../../actions/user-actions';
import { store } from '../../index';
import Globals from './Globals';
import IOStatus from './IOStatus';
import Urls from './Urls';
import User from './User';


export default class Auth {


    static SignOut = () => {

        return new Promise( ( resolve ) => {
            User.RemoveTokens();
            store.dispatch( userActions.replaceUser( new User() ) );
            Globals.FirebaseApp.auth().signOut().then(() => { }).catch( ()=> {} );
            resolve( [IOStatus.OK, null ] );
        } );
    }

    static LoginWithToken = ( token  ) => {

        return new Promise( ( resolve ) => {

            let url = new Urls().LoginUrl();

            let params = {
                        }

            superagent.post( url )
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('x-requested-with', 'XMLHttpRequest')
            .send( params )
            .auth(token, { type: 'bearer' })
            .end((error, response) => {

                if ( error )
                {
                     if ( response && response.statusCode )
                    {
                        resolve( [response.statusCode, null] )
                    }
                    else
                    {
                        resolve( [IOStatus.SERVER_ERROR, null ] );
                    }
                    return;
                }

                if ( ! response )
                {
                    resolve( [ IOStatus.SERVER_ERROR, null ] );
                    return;
                }
                if ( response.body && response.body.successful )
                {
                    resolve( [IOStatus.OK, response.body ] );
                    return;
                }
                resolve( [ IOStatus.UNKNOWN_ERROR, null ] );
            });
        });
    }

    static Login = ( email, password  ) => {

        return new Promise( ( resolve ) => {

            let url = new Urls().LoginUrl();

            let params = {
                        email: email,
                        password: password,
                        }

            superagent.post( url )
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('x-requested-with', 'XMLHttpRequest')
            .send( params )
            // .auth(user.UserToken, { type: 'bearer' })
            .end((error, response) => {


                if ( error )
                {
                     if ( response && response.statusCode )
                    {
                        resolve( [response.statusCode, response.body] )
                    }
                    else
                    {
                        resolve( [IOStatus.SERVER_ERROR, null ] );
                    }
                    return;
                }

                if ( ! response )
                {
                    resolve( [ IOStatus.SERVER_ERROR, null ] );
                    return;
                }
                if ( response.body && response.body.successful )
                {
                    resolve( [IOStatus.OK, response.body ] );
                    return;
                }
                resolve( [ IOStatus.UNKNOWN_ERROR, response.body ] );
            });
        });
    }


    static VerifyEmail = ( email, code  ) => {

        return new Promise( ( resolve ) => {

            let url = new Urls().VerifyEmailUrl();

            let params = {
                        email: email,
                        code: code,
                        }

            superagent.post( url )
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('x-requested-with', 'XMLHttpRequest')
            .send( params )
            // .auth(user.UserToken, { type: 'bearer' })
            .end((error, response) => {

                if ( error )
                {
                     if ( response && response.statusCode )
                    {
                        resolve( [response.statusCode, response.body] )
                    }
                    else
                    {
                        resolve( [IOStatus.SERVER_ERROR, null ] );
                    }
                    return;
                }

                if ( ! response )
                {
                    resolve( [ IOStatus.SERVER_ERROR, null ] );
                    return;
                }
                if ( response.body && response.body.successful )
                {
                    resolve( [IOStatus.OK, response.body ] );
                    return;
                }
                resolve( [ IOStatus.UNKNOWN_ERROR, response.body ] );
            });
        });
    }



    static ResendVerificationCode = ( email  ) => {

        return new Promise( ( resolve ) => {

            let url = new Urls().ResendVerificationCodeUrl();

            let params = {
                        email: email,
                         }

            superagent.post( url )
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('x-requested-with', 'XMLHttpRequest')
            .send( params )
            // .auth(user.UserToken, { type: 'bearer' })
            .end((error, response) => {

                if ( error )
                {
                     if ( response && response.statusCode )
                    {
                        resolve( [response.statusCode, response.body] )
                    }
                    else
                    {
                        resolve( [IOStatus.SERVER_ERROR, null ] );
                    }
                    return;
                }

                if ( ! response )
                {
                    resolve( [ IOStatus.SERVER_ERROR, null ] );
                    return;
                }
                if ( response.body && response.body.successful )
                {
                    resolve( [IOStatus.OK, response.body ] );
                    return;
                }
                resolve( [ IOStatus.UNKNOWN_ERROR, response.body ] );
            });
        });
    }


    static CreateAccount = ( email, password, name ) => {

        return new Promise( ( resolve ) => {

            let url = new Urls().CreateAccountUrl();

            let params = {
                        email: email,
                        password: password,
                        name: name
                        }

            superagent.post( url )
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('x-requested-with', 'XMLHttpRequest')
            .send( params )
            // .auth(user.UserToken, { type: 'bearer' })
            .end((error, response) => {

              //  console.log( error, response );

                if ( error )
                {
                    if ( response && response.statusCode )
                    {
                        resolve( [response.statusCode, response.body] )
                    }
                    else
                    {
                        resolve( [IOStatus.SERVER_ERROR, null ] );
                    }
                    return;
                }

                if ( ! response )
                {
                    resolve( [ IOStatus.SERVER_ERROR, null ] );
                    return;
                }
                if ( response.body && response.body.successful )
                {
                    resolve( [IOStatus.OK, response.body ] );
                    return;
                }
                resolve( [ IOStatus.UNKNOWN_ERROR, response.body ] );
            });
        });
    }


    static InitAccountRecover = ( email ) => {

        return new Promise( ( resolve ) => {

            let url = new Urls().UserInitRecoverAccountUrl();

            let params = {
                        email: email
                        }

            superagent.post( url )
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('x-requested-with', 'XMLHttpRequest')
            .send( params )
            // .auth(user.UserToken, { type: 'bearer' })
            .end((error, response) => {

                if ( error )
                {
                    if ( response && response.statusCode )
                    {
                        resolve( [response.statusCode, response.body] )
                    }
                    else
                    {
                        resolve( [IOStatus.SERVER_ERROR, null ] );
                    }
                    return;
                }

                if ( ! response )
                {
                    resolve( [ IOStatus.SERVER_ERROR, null ] );
                    return;
                }
                if ( response.body && response.body.successful )
                {
                    resolve( [IOStatus.OK, response.body ] );
                    return;
                }
                resolve( [ IOStatus.UNKNOWN_ERROR, response.body ] );
            });
        });
    }


    static ResetPassword = ( email, reset, password ) => {

        return new Promise( ( resolve ) => {

            let url = new Urls().AccountResetPasswordUrl();

            let params = {
                        email: email,
                        reset: reset,
                        password: password
                        }

            superagent.post( url )
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('x-requested-with', 'XMLHttpRequest')
            .send( params )
            // .auth(user.UserToken, { type: 'bearer' })
            .end((error, response) => {

                if ( error )
                {
                    if ( response && response.statusCode )
                    {
                        resolve( [response.statusCode, response.body] )
                    }
                    else
                    {
                        resolve( [IOStatus.SERVER_ERROR, null ] );
                    }
                    return;
                }

                if ( ! response )
                {
                    resolve( [ IOStatus.SERVER_ERROR, null ] );
                    return;
                }
                if ( response.body && response.body.successful )
                {
                    resolve( [IOStatus.OK, response.body ] );
                    return;
                }
                resolve( [ IOStatus.UNKNOWN_ERROR, response.body ] );
            });
        });
    }
}