// possible results for loading and saving

export default class IOStatus {

    static OK               = 0;
    static UNKNOWN_ERROR    = 1;
    static NOT_AUTHORIZED   = 401;
    static INVALID_REQUEST  = 400;
    static SERVER_ERROR     = 500;
    static NOT_FOUND        = 404;
    static RESOURCE_EXISTS  = 409;


    // custom returns in response body
    static VERIFICATION_REQUIRED = 1020;
    static VERIFICATION_FAILED = 1021;
    static SUBMISSION_ALREADY_SUBMITTED = 1022;

    // when creating account
    static ACCOUNT_EXISTS = 1019;
}
