import React from 'react';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons'
import SearchBox from '../ui/SearchBox';

const data = [
                { id: 1, title: 'Pepsi Australia', position: 'PA3456 - Dishwasher', applicant: 'Billy Burke', date: 'Sep 9' },
                { id: 2, title: 'Pepsi Australia', position: 'PA3456 - Delivery Driver', applicant: 'Sally Willis', date: 'Sep 8' },
                { id: 3, title: 'McDonalds', position: "MC-1267 - Burger maker", applicant: 'Justin Grouse', date: 'Sep 8' }


                ];
const columns = [
    {
        name: "",
        sortable: false,
    selector: () => { return (<FontAwesomeIcon icon={faEye}></FontAwesomeIcon>  )},
    grow: 0,
    width: '36px',
    center: true
    },
  {
    name: 'Client',
    selector: 'title',
    sortable: true,
  },
  {
    name: 'Position',
    selector: 'position',
    sortable: true,
  },
  {
    name: 'Applicant',
    selector: 'applicant',
    sortable: true,
  },
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    grow: 0,
    width: '70px'
  },
];

const customStyles = {
    rows: {
      style: {
        minHeight: '36px', // override the row height
      }
    },
    headCells: {
      style: {
          paddingLeft: '8px'
      },
    },
    headRow:{
        style: {
            minHeight: '18px',
            background: '#e8e8e8',
            borderTopRightRadius: '6px',
            borderTopLeftRadius: '6px',
            paddingTop: '8px',
            paddingBottom: '8px',

        }
    },
    cells: {
      style: {
        paddingLeft: '8px', // override the cell padding for data cells
        paddingRight: '8px',
      },
    },
    pagination: {
        style: {
            minHeight: '0'
        }
    }
  };



export default class LatestSubmissions extends React.Component {


    render() {
        return (

            <div className="latest_submissions_holder">
                <div className="submissions_header">
                    <div className="submissions_title">Unprocessed Submissions</div>
                    <div className="submissions_search"><SearchBox /></div>
                </div>
                <div className="latest_submissions_list">

                <DataTable
                    title=""
                    noHeader={true}
                    pagination={true}
                    columns={columns}
                    customStyles={customStyles}
                    data={data}
                />
                </div>
            </div>
        )
    }
}