import React from 'react';
import AuthStatus from '../classes/AuthStatus';
import Theme from '../classes/Theme';
import Utils from '../classes/Utils';
import AppHeaderButton from './AppHeaderButton';



export default class AppHeader extends React.Component {

    saveBioZing = () => {
        if ( this.props.callback &&  this.props.callback.saveBioZing )
        {
            this.props.callback.saveBioZing();
        }
    }

    loadBioZing = () => {
        if ( this.props.callback &&  this.props.callback.loadBioZing )
        {
            this.props.callback.loadBioZing();
        }
    }


    signIn = () => {
       // console.log( "sign in")
    }

    signOut = async () => {

        if ( this.props.callback &&  this.props.callback.SignOut )
        {
            this.props.callback.SignOut();
        }

    }

    render() {

        if ( this.props.authStatus === AuthStatus.NOT_SIGNED_IN )
        {
            return null;
        }

        if ( this.props.authStatus === AuthStatus.CHECKING )
        {
            return null;
        }

        let theme = new Theme();
        let guideUrl =  theme.UserGuide();
        let showUserGuide = Utils.HasString(guideUrl);


        return <div className="header">
            {this.props.authStatus === AuthStatus.SIGNED_IN && <div className="header_title"><div className="header_logo"></div></div>}
            {this.props.authStatus === AuthStatus.SIGNED_IN && <div className="header_buttons">
            {showUserGuide && <a href={guideUrl} target="_blank" rel="noopener noreferrer"><AppHeaderButton title="USER GUIDE"></AppHeaderButton></a>}
                {this.props.authStatus === AuthStatus.NOT_SIGNED_IN && <AppHeaderButton title="Sign in" clicked={this.signIn} />}
                {this.props.authStatus === AuthStatus.SIGNED_IN && <AppHeaderButton title="Sign out" clicked={this.signOut} />}
                </div>
            }
            </div>

    }

}