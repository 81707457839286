
import * as shortlistActions from '../../actions/shorlisted-actions';
import { store } from '../../index';
import BioZing from './BioZing';
import IOStatus from './IOStatus';


// combined data from application and submission to display to recruiter
export class ShortlistedSubmission {

    SubmissionID = -1;
    Status = 0;
    CandidateName = "";
    CandidateEmail = "";
    CandidatePhone = "";
    ApplicationLink = "";
    LastUpdate = 0;
    Client = "";
    PositionID = "";
    ApplicationID = 0;
    PositionTitle = "";

    // used to highlight in interface when a new message is received
    New = false;
}


export default class ShortlistedSubmissions {

    // array of ShortlistedSubmission
    Submissions = [];

    submissionsUpdated = () => {

        store.dispatch( shortlistActions.replaceShortlisted( this.Submissions ) );
    }



    LoadFromServer = async ( options ) => {

        let bz = new BioZing();
        let response = await bz.LoadShortlistedSubmissions(options);

        if ( ! response || response.result !== IOStatus.OK )
        {
            this.submissionsUpdated();
            return;
        }

        if ( ! response.applications || response.applications.length === 0 )
        {
            this.submissionsUpdated();
            return;
        }
        if ( ! response.submissions || response.submissions.length === 0 )
        {
            this.submissionsUpdated();
            return;
        }

        for ( var i = 0; i < response.submissions.length; i++ )
        {
            let sub = response.submissions[i];
            let short = new ShortlistedSubmission();

            short.SubmissionID = sub.id;
            short.CandidateName = sub.applicant_name;
            short.CandidateEmail = sub.applicant_email;
            short.CandidatePhone = sub.applicant_phone;
            short.ApplicationLink = sub.application_link;
            short.LastUpdate = sub.last_update;
            short.Status = sub.status;

            // fill in applicaiton details
            for ( var a = 0; a < response.applications.length; a++ )
            {
                let app = response.applications[a];
                if ( app.post_link === short.ApplicationLink )
                {
                    short.Client = app.client;
                    short.PositionID = app.position_id;
                    short.PositionTitle = app.title;
                    short.ApplicationID = app.id;

                    this.Submissions.push( short );
                    break;
                }
            }
        }
        this.submissionsUpdated();

    }

    // single retrieved submission
    static CreateFromAPIReturn = ( application, submission ) => {


        if ( ! application || ! submission )
        {
            return null;
        }

        let sub = submission;
        let app = application;

        let short = new ShortlistedSubmission();

        short.SubmissionID = sub.id;
        short.CandidateName = sub.applicant_name;
        short.CandidateEmail = sub.applicant_email;
        short.CandidatePhone = sub.applicant_phone;
        short.ApplicationLink = sub.application_link;
        short.LastUpdate = sub.last_update;
        short.Status = sub.status;

        short.Client = app.client;
        short.PositionID = app.position_id;
        short.PositionTitle = app.title;
        short.ApplicationID = app.id;

        return short;
    }
}