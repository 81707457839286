import React from 'react';

export default class Spacer extends React.Component {
  constructor(props) {
    super(props);

    this.state = { height: this.props.height || 0 };
    }

    render() {
        let style = { height: this.state.height + "px" };
        return <div style={style}></div>
    }
}