import * as types from './action-types';

// User class instance
export const replaceUser = (user) => {
  return {
    type: types.USER_REPLACE,
    user
  };
}

  // fields to update user, eg { LoggedIntoFirebase: true }
  export const updateUser = (fields) => {
    return {
      type: types.USER_UPDATE,
      fields
    };

}
