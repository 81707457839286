import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './js/App';
import * as serviceWorker from './serviceWorker';
import Firebase from './js/Firebase/Firebase';
import { FirebaseContextProvider } from './js/Firebase/context';

import configureStore from './store/configure-store';
import {Provider} from 'react-redux';

const store = configureStore();

// let firebase = new Firebase();

/*
messaging.onMessage((payload) => {
  console.log('Message received. ', payload);
  // ...
});
*/

ReactDOM.render(
  <React.StrictMode>
  <Provider store={store}>
  <FirebaseContextProvider value={new Firebase()}>
    <App />
    </FirebaseContextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

export {
  store
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
