// class to hold all information to create the BioZing

//import Questionnaire from './Questionnaire';
//import JobDetails from './JobDetails';
//import VideoInstructions from './VideoInstructions';
//import RecruiterDetails from './RecruiterDetails';
//import ClientDetails from './ClientDetails';
import superagent from 'superagent';
import IOStatus from './IOStatus';
import Urls from './Urls';
import User from './User';

export default class BioZing {


 //   JobDetails = new JobDetails();
 //   Questionnaire = new Questionnaire();
 //   VideoInstructions = new VideoInstructions();
 //   RecruiterDetails  = new RecruiterDetails();
 //   ClientDetails = new ClientDetails()

/*
    static Copy = ( src ) => {

        if ( ! src ) return src;

        let dest = new BioZing();

        dest.JobDetails = JobDetails.Copy( src.JobDetails );
        dest.VideoInstructions = VideoInstructions.Copy( src.VideoInstructions );
        dest.Questionnaire = Questionnaire.Copy( src.Questionnaire );
        dest.RecruiterDetails = RecruiterDetails.Copy( src.RecruiterDetails );
        dest.ClientDetails = ClientDetails.Copy( src.ClientDetails );

        return dest;

    }
*/
/*
    Save = () => {

        return new Promise( ( resolve ) => {

            let url = new Urls().SaveApplicationUrl();
            let user = User.CurrentUser();
            console.log( user );

            let params = {  "update": 1,
                            "position_id": this.JobDetails.PositionID,
                            "position_title": this.JobDetails.Title,
                            "client": this.JobDetails.Client,
                            "public_title": this.JobDetails.PublicTitle,
                            "video_instructions": this.VideoInstructions.AsJSON(),
                            "client_details": this.ClientDetails.AsJSON(),
                            "recruiter_details": this.RecruiterDetails.AsJSON(),
                            "questionnaire": this.Questionnaire.AsJSON()
                        }

            superagent.post( url )
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('x-requested-with', 'XMLHttpRequest')
            .send( params )
            .auth(user.UserToken, { type: 'bearer' })
            .end((error, response) => {

                if ( error )
                {
                    console.log( error, response )
                    if ( response && response.statusCode )
                    {
                        resolve( response.statusCode )
                    }
                    else
                    {
                        resolve(  IOStatus.SERVER_ERROR );
                    }
                    return;
                }

                if ( ! response )
                {
                    resolve(  IOStatus.SERVER_ERROR );
                    return;
                }

                console.log( response.body );
                resolve( IOStatus.OK);
            });
        });
    }
*/

    Update = ( application_id, options ) => {

            return new Promise( ( resolve ) => {

                let url = new Urls().SaveApplicationUrl() + application_id;
                let user = User.CurrentUser();

                let params = {};
                if ( options )
                {
                    if ( options.active === 1 ) params.active = 1;
                    if ( options.active === 0 ) params.active = 0;
                }

                superagent.put( url )
                .set('Accept', 'application/json')
                .set('Content-Type', 'application/json')
                .set('x-requested-with', 'XMLHttpRequest')
                .send( params )
                .auth(user.UserToken, { type: 'bearer' })
                .end((error, response) => {

                    if ( error )
                    {
                       // console.log( error, response )
                        if ( response && response.statusCode )
                        {
                            resolve( response.statusCode )
                        }
                        else
                        {
                            resolve(  IOStatus.SERVER_ERROR );
                        }
                        return;
                    }

                    if ( ! response )
                    {
                        resolve(  IOStatus.SERVER_ERROR );
                        return;
                    }

                   // console.log( response.body );
                    resolve( IOStatus.OK);
                });
            });
        }


    PostApplication = ( application_id ) => {

        return new Promise( ( resolve ) => {

            let url = new Urls().PostApplicationUrl() + application_id;
            let user = User.CurrentUser();

            let params = {

                        }

            superagent.post( url )
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('x-requested-with', 'XMLHttpRequest')
            .send( params )
            .auth(user.UserToken, { type: 'bearer' })
            .end((error, response) => {

                if ( error )
                {
                    // console.log( error, response )
                    if ( response && response.statusCode )
                    {
                        resolve( {result: response.statusCode } )
                    }
                    else
                    {
                        resolve( {result: IOStatus.SERVER_ERROR } );
                    }
                    return;
                }

                if ( ! response )
                {
                    resolve( {result: IOStatus.SERVER_ERROR } );
                    return;
                }
                if ( response.body && response.body.successful )
                {
                    resolve( { result: IOStatus.OK, post_link: response.body.post_link });
                    return;
                }
                resolve( {result:  IOStatus.UNKNOWN_ERROR } );
            });
        });
    }

    LoadSummaryList = (options) => {

        return new Promise( ( resolve ) => {

            let url = new Urls().ListApplicationsSummaryUrl();
            let user = User.CurrentUser();

            let params = {

                        }

            if ( options )
            {

                if ( options.hasOwnProperty('active') && (options.active === 0 || options.active === "0" || options.active === false) )
                {
                    params.active = 0;
                }
                if ( options.hasOwnProperty('draft') && (options.draft === 1 || options.draft === "1" || options.draft === true ) )
                {
                    params.draft = 1;
                }

            }

            superagent.get( url )
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('x-requested-with', 'XMLHttpRequest')
            .query( params )
            .auth(user.UserToken, { type: 'bearer' })
            .end((error, response) => {

               // console.log( "summary", error, response );
                if ( error )
                {
                    if ( response && response.statusCode )
                    {
                        resolve( {result: response.statusCode } )
                    }
                    else
                    {
                        resolve( {result: IOStatus.SERVER_ERROR } );
                    }
                    return;
                }

                if ( ! response )
                {
                    resolve( {result: IOStatus.SERVER_ERROR } );
                    return;
                }

                if ( response.body && response.body.successful )
                {
                    resolve( { result: IOStatus.OK, items: response.body.payload });
                    return;
                }
                resolve( {result:  IOStatus.UNKNOWN_ERROR } );
            });
        });

    }


    LoadList = ( options ) => {


        return new Promise( ( resolve ) => {

            let url = new Urls().ListApplicationsUrl();
            let user = User.CurrentUser();

            let params = {

                        }

            if ( options )
            {
                if ( options.hasOwnProperty('active') && (options.active === 0 || options.active === "0" || options.active === false) )
                {
                    params.active = 0;
                }
            }

            superagent.get( url )
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('x-requested-with', 'XMLHttpRequest')
            .query( params )
            .auth(user.UserToken, { type: 'bearer' })
            .end((error, response) => {

                if ( error )
                {
                    if ( response && response.statusCode )
                    {
                        resolve( {result: response.statusCode } )
                    }
                    else
                    {
                        resolve( {result: IOStatus.SERVER_ERROR } );
                    }
                    return;
                }

                if ( ! response )
                {
                    resolve( {result: IOStatus.SERVER_ERROR } );
                    return;
                }

                if ( response.body && response.body.successful )
                {
                    resolve( { result: IOStatus.OK, items: response.body.applications });
                    return;
                }
                resolve( {result:  IOStatus.UNKNOWN_ERROR } );
            });
        });
    }


    LoadSubmissions = ( post_link, options ) => {

        return new Promise( ( resolve ) => {

            let url = new Urls().ListSubmissionsUrl() + post_link;
            let user = User.CurrentUser();

            let params = {

                        }

            if ( options )
            {
            }

            superagent.get( url )
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('x-requested-with', 'XMLHttpRequest')
            .query( params )
            .auth(user.UserToken, { type: 'bearer' })
            .end((error, response) => {

                if ( error )
                {
                    if ( response && response.statusCode )
                    {
                        resolve( {result: response.statusCode } )
                    }
                    else
                    {
                        resolve( {result: IOStatus.SERVER_ERROR } );
                    }
                    return;
                }

                if ( ! response )
                {
                    resolve( {result: IOStatus.SERVER_ERROR } );
                    return;
                }

                if ( response.body && response.body.successful )
                {
                    resolve( { result: IOStatus.OK, submissions: response.body.submissions });
                    return;
                }
                resolve( {result:  IOStatus.UNKNOWN_ERROR } );
            });
        });
    }


    LoadShortlistedSubmissions = ( options ) => {

        return new Promise( ( resolve ) => {

            let url = new Urls().ListShortlistedSubmissionsUrl();
            let user = User.CurrentUser();

            let params = {

                        }

            if ( options )
            {

                if ( options.hasOwnProperty('active') && (options.active === 0 || options.active === "0" || options.active === false) )
                {
                    params.active = 0;
                }

            }

            superagent.get( url )
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('x-requested-with', 'XMLHttpRequest')
            .query( params )
            .auth(user.UserToken, { type: 'bearer' })
            .end((error, response) => {

                if ( error )
                {
                    if ( response && response.statusCode )
                    {
                        resolve( {result: response.statusCode } )
                    }
                    else
                    {
                        resolve( {result: IOStatus.SERVER_ERROR } );
                    }
                    return;
                }

                if ( ! response )
                {
                    resolve( {result: IOStatus.SERVER_ERROR } );
                    return;
                }

                if ( response.body && response.body.successful )
                {
                    resolve( { result: IOStatus.OK, applications: response.body.applications, submissions: response.body.submissions });
                    return;
                }
                resolve( {result:  IOStatus.UNKNOWN_ERROR } );

            });
        });


}

// get single shortlist
LoadShortlistedSubmission = ( submission_id, options ) => {

    return new Promise( ( resolve ) => {

        let url = new Urls().LoadShortlistedSubmissionUrl() + submission_id;
        let user = User.CurrentUser();
        let params = {

                    }

        if ( options )
        {

        }

        superagent.get( url )
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/json')
        .set('x-requested-with', 'XMLHttpRequest')
        .query( params )
        .auth(user.UserToken, { type: 'bearer' })
        .end((error, response) => {

            if ( error )
            {
                if ( response && response.statusCode )
                {
                    resolve( {result: response.statusCode } )
                }
                else
                {
                    resolve( {result: IOStatus.SERVER_ERROR } );
                }
                return;
            }

            if ( ! response )
            {
                resolve( {result: IOStatus.SERVER_ERROR } );
                return;
            }

            if ( response.body && response.body.successful )
            {
                resolve( { result: IOStatus.OK, application: response.body.application, submission: response.body.submission });
                return;
            }
            resolve( {result:  IOStatus.UNKNOWN_ERROR } );

        });
    });


}

/*
    LoadPublic = ( post_link ) => {

        return new Promise( ( resolve ) => {

            let url = new Urls().LoadPublicApplicationUrl() + post_link;
            //let user = User.CurrentUser();

            let params = {

                        }

            superagent.get( url )
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('x-requested-with', 'XMLHttpRequest')
            .send( params )
            // .auth(user.UserToken, { type: 'bearer' })
            .end((error, response) => {

                if ( error )
                {
                    console.log( error, response )
                    if ( response && response.statusCode )
                    {
                        resolve( {result: response.statusCode } )
                    }
                    else
                    {
                        resolve( {result: IOStatus.SERVER_ERROR } );
                    }
                    return;
                }

                if ( ! response )
                {
                    resolve( {result: IOStatus.SERVER_ERROR } );
                    return;
                }

                console.log( response.body );
                if ( response.body && response.body.successful )
                {
                    resolve( { result: IOStatus.OK, application: response.body.application });
                    return;
                }
                resolve( {result:  IOStatus.UNKNOWN_ERROR } );
            });
        });
    }

    Load = ( application_id ) => {

        return new Promise( ( resolve ) => {

            let url = new Urls().LoadApplicationUrl() + application_id;
            let user = User.CurrentUser();

            let params = {

                        }

            superagent.get( url )
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('x-requested-with', 'XMLHttpRequest')
            .send( params )
            .auth(user.UserToken, { type: 'bearer' })
            .end((error, response) => {

                if ( error )
                {
                    console.log( error, response )
                    if ( response && response.statusCode )
                    {
                        resolve( {result: response.statusCode } )
                    }
                    else
                    {
                        resolve( {result: IOStatus.SERVER_ERROR } );
                    }
                    return;
                }

                if ( ! response )
                {
                    resolve( {result: IOStatus.SERVER_ERROR } );
                    return;
                }

                console.log( response.body );
                if ( response.body && response.body.successful )
                {
                    resolve( { result: IOStatus.OK, application: response.body.application });
                    return;
                }
                resolve( {result:  IOStatus.UNKNOWN_ERROR } );
            });
        });
    }
*/

    /*

    client: "Billy and Co's Pet Services"
client_details: "{"MediaUrl":"https://www.youtube.com/watch?v=2_kOM6A8ae8","MediaType":0}"
date_created: "2020-09-21 04:10:23"
id: 4
last_update: "2020-09-21 04:10:56"
owner: "HLpOgLf7KAWA1Nt7w8U6oWLPdYg2"
position_id: "#bcps-1241"
post_link: null
posted_date: "0000-00-00 00:00:00"
public_title: "Casual Dog Walker"
questionnaire: "{"Questions":[{"Type":1,"Question":"What is your fitness level?","PlaceHolder":"","Answers":[],"MaxCharacters":500,"ID":"WygtZmVH","Tag":-1},{"Type":4,"Question":"How long have you been walking?","PlaceHolder":"","Answers":["Less than 1 year","1 to 2 years","More than 2 years"],"MaxCharacters":500,"ID":"xqCVo33k","Tag":-1},{"Type":2,"Question":"What experience do you have with other pets?","PlaceHolder":"","Answers":[],"MaxCharacters":500,"ID":"0qhFHSBk","Tag":-1},{"Type":3,"Question":"What other pets have you looked after?","PlaceHolder":"","Answers":["Cat","Fish","Birds","Hamster","Reptiles"],"MaxCharacters":500,"ID":"js6woGMn","Tag":-1}]}"
recruiter_details: "{"Name":"Fred Burke","Email":"fb@burke.com.au","Phone":"+61412288776"}"
status: 0
title: "Dog Walker"
video_instructions: "{"MaxLength":90,"Questions":["What is your opinion on cats? ","What about fish?","How would you stop a dog fight?"]}"

*/
/*
    CreateFromAPIReturn = ( data ) =>{

        if ( ! data )
        {
            return;
        }
        this.JobDetails = new JobDetails();
        this.JobDetails.Client = Utils.EnsureString( data.client );
        this.JobDetails.PositionID = Utils.EnsureString( data.position_id );
        this.JobDetails.Title = Utils.EnsureString( data.title );
        this.JobDetails.PublicTitle = Utils.EnsureString( data.public_title );

        let cd = new ClientDetails();

        if  ( data.client_details )
        {
            try {
                cd = JSON.parse(data.client_details);
            } catch( e ) { console.log( e ) }
        }
        this.ClientDetails = Object.assign( new ClientDetails(), cd );

        let vi = new VideoInstructions();
        if ( data.video_instructions )
        {
            try {
                vi = JSON.parse(data.video_instructions);
            } catch( e ) { console.log( e ) }
        }
        this.VideoInstructions = Object.assign( new VideoInstructions(), vi );

        let q = new Questionnaire();
        if ( data.questionnaire )
        {
            try {
                q = JSON.parse(data.questionnaire);
            } catch( e ) { console.log( e ) }
        }
        this.Questionnaire = Object.assign( new Questionnaire(), q );

        let rd = new RecruiterDetails();
        if ( data.recruiter_details )
        {
            try {
                rd = JSON.parse(data.recruiter_details);
            } catch( e ) { console.log( e ) }
        }
        this.RecruiterDetails = Object.assign( new RecruiterDetails(), rd );

    }
*/


}