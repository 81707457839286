export default class PositionType {
    static POSTED = 0;
    static DRAFT = 1;
    static ARCHIVED = 2;

    static Label = ( type ) => {
        switch( type )
        {
            case PositionType.POSTED:

                return "Published Positions";

            case PositionType.DRAFT:

                return "Draft Positions";

          case PositionType.ARCHIVED:

                return "Archived Positions";

            default: return "";
        }
    }

}