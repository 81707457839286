import { faExternalLinkAlt, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import DataTable from 'react-data-table-component';
import { subscribe } from "redux-subscriber";
import { ApplicationStatus } from '../classes/ApplicationsSummary';
import Broadcast from '../classes/Broadcast';
import Globals from '../classes/Globals';
import ShortlistedSubmissions from '../classes/ShortlistedSubmisions';
import Urls from '../classes/Urls';
import User from '../classes/User';
import SearchBox from '../ui/SearchBox';
import Spacer from '../ui/Spacer';
import SubmissionStatusSlug from './SubmissionStatusSlug';

export default class ShortlistedSubmissionsDisplay extends React.Component {

    subscribeList = null;

    UNFILTERED = -1;
    allSubmissions = [];

    // when recruiter makes submission to be shortlisted, msg get sent with just it's id, so load new one and add it to list
    // jsut reloading whole list at the moment,
    lastAddedSubmission = 0;

    constructor( props )
    {
        super(props);

        this.state = { loadingList: true, submissions: [], submissionFilter: this.UNFILTERED, searchTerm: "" };
    }

    componentDidMount() {

      let broadcast = Globals.Broadcast.Channel();
      broadcast.addEventListener('message', this.handleBroadcastMessage);

        this.subscribeList = subscribe('shortlist', state => {

                 // remove all submissions and reload
                // data table doesn't reload when status changes, only happens if id is changed??

                this.allSubmissions = state.shortlist.list;

                this.setState( {  submissions: [] }, () => {
                    this.setState( { submissions: this.filterSubmissions( this.state.submissionFilter, this.allSubmissions, this.state.searchTerm ) }, () =>{
                        this.setState( { loadingList: false } );
                    } )
                  })
                 } )



        this.loadShortlisted();

        /*
        if ( Utils.DevMode() )
        {
          setTimeout( () => {
            console.log( "sending msg" );
            this.handleBroadcastMessage( { type: Broadcast.SUBMISSION_STATUS_UPDATED, "id": 27, "status": 3 } );
          }, 10000 )
        }
        */

        //
    }

    componentWillUnmount() {

      let broadcast = Globals.Broadcast.Channel();
      broadcast.removeEventListener('message', this.handleBroadcastMessage);

        if ( this.subscribeList )
        {
            this.subscribeList();
            this.subscribeList = null;
        }
    }

  // received msg from another tab
  handleBroadcastMessage = ( msg ) => {

    if ( ! msg )
    {
      return;
    }

    switch( msg.type )
    {

      case Broadcast.SUBMISSION_STATUS_UPDATED:
      {

        let id = msg.id;
        let status = msg.status;
        if ( ! ApplicationStatus.IsValid( status ) || isNaN( id ) || parseInt(id) <= 0 )
        {
          return;
        }

        if ( status === ApplicationStatus.SHORTLISTED )
        {
          // submission has been marked as shortlisted, add to to shortlisted list
           //  this.loadShortlistSubmission( id );
           // jsut reload list - TODO just load updated info
           this.loadShortlisted();
        }
      }
        break;

      default:
          break;
    }
  }



    columns = [
        {
          name: <div className="submissions_table_header">Applicant</div>,
          selector: 'CandidateName',
          sortable: true,
        },
        {
            name: <div className="submissions_table_header">Client</div>,
            selector: 'Client',
            sortable: true,
          },
          {
            name: <div className="submissions_table_header">Position</div>,
            selector: 'PositionTitle',
            sortable: true,
          },
        {
          name: <div className="submissions_table_header">Status</div>,
          //name: <StatusPickerButton callback={this} />,
          selector: 'Status',
          cell: row => <SubmissionStatusSlug type={row.Status} count={-1} isClient={true} />,
          sortable: true,
          center: true,
          width: '110px',
        }, /*
        {
          name: <div className="submissions_table_header">Interview</div>,
          selector: 'interview_date',
          sortable: true,
          grow: 0,
          width: '100px',
          format: row => <InterviewDisplayDate date={row.interview_date} status={row.status} interview_status={row.interview_status} />,
          center: true
        }, */
        {
          name: <div className="submissions_table_header">Updated</div>,
          selector: 'LastUpdate',
          sortable: true,
          grow: 0,
          width: '90px',
          format: row => this.formatDate(row.LastUpdate),
          center: true
        },
        {
          name: "",
          sortable: false,
          selector: (row) => { return ( <div onClick={() => this.showSubmission(row.SubmissionID)} title="View submission" className="submissions_display_link_icon" ><FontAwesomeIcon icon={faExternalLinkAlt}></FontAwesomeIcon></div>  )},
          grow: 0,
          width: '40px',
          center: true
        }
      ];

      customStyles = {

          rows: {
            style: {
              minHeight: '36px', // override the row height
              fontSize: '15px'
            }
          },
          headCells: {
            style: {
                paddingLeft: '8px',
                paddingRight: '8px',
                fontSize: '15px',
            },
          },
          headRow:{
              style: {
                  minHeight: '18px',
                  background: '#e8e8e8',
                  borderTopRightRadius: '6px',
                  borderTopLeftRadius: '6px',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  fontSize: '15px'

              }
          },
          cells: {
            style: {
              paddingLeft: '8px', // override the cell padding for data cells
              paddingRight: '8px',
            },
          },
          pagination: {
              style: {
                  minHeight: '0'
              }
          }
        };

        conditionalRowStyles = [
          {
            when: row => row.New,
            style: {
              backgroundColor: '#f0e7ff',
              color: 'black',
            },
          }
        ];

      formatDate = ( date ) =>
      {
        let d = new Date( date * 1000 ).toLocaleDateString(undefined, {
          day : 'numeric',
          month : 'short'} );
        return d;
      }

      /*
      loadShortlistSubmission = async ( submission_id, options ) => {
        let bz = new BioZing();
        let response = await bz.LoadShortlistedSubmission(submission_id, options);
     //   console.log (response );
        if ( response && response.result === IOStatus.OK )
        {
          let short = ShortlistedSubmissions.CreateFromAPIReturn( response.application, response.submission );
      //    console.log( short );
        }
      }
      */

    loadShortlisted = async ( ) => {
        let sl = new ShortlistedSubmissions();
        await sl.LoadFromServer();
        // Globals.FirebaseInstance.AddShortlistListeners();
    }

    filterSubmissions = ( filter, subs, search ) => {

      if ( ! subs )
      {
        return [];
      }

      let doSearch = search && search.length > 0;
      let searchTerm = "";
      if ( doSearch )
      {
        // TODO check against non latin characters
        searchTerm = search.toLowerCase();
      }


      if ( filter === this.UNFILTERED && ! doSearch )
      {
        return subs;
      }

      let filtered = [];
      for ( var i = 0; i < subs.length; i++ )
      {
        let sub = subs[i];
       // if ( sub.status === filter || filter === this.UNFILTERED || ( filter === ApplicationStatus.DECLINED && sub.status === ApplicationStatus.CLIENT_DECLINED ) )
       // {
          if ( doSearch )
          {
            if ( sub.CandidateName && sub.CandidateName.toLowerCase().indexOf( searchTerm ) !== -1 )
            {
              filtered.push( sub )//  ( Object.assign( new ShortlistedSubmission(), sub ) );
            }
            else
            {
              if ( sub.Client && sub.Client.toLowerCase().indexOf( searchTerm ) !== -1 )
              {
                filtered.push( sub )//  ( Object.assign( new ShortlistedSubmission(), sub ) );
              }
              else
              {
                if ( sub.PositionTitle && sub.PositionTitle.toLowerCase().indexOf( searchTerm ) !== -1 )
                {
                  filtered.push( sub )//  ( Object.assign( new ShortlistedSubmission(), sub ) );
                }
              }
            }
          }
          else
          {
            filtered.push( sub );// Object.assign( {}, sub ) );
          }
       // }
      }
      return filtered;
    }

    reloadClicked = () => {

      this.setState( { loadingList: true } );
      this.loadShortlisted();
    }

    doSearch = ( txt ) => {

      this.setState( { searchTerm: txt, submissions: this.filterSubmissions( this.state.submissionFilter, this.allSubmissions, txt ) } );
    }

    showSubmission = (id) => {
        if ( id && id > 0 )
        {
          let user = User.CurrentUser();

          window.open( new Urls().ViewSubmissionUrl() + id + "?token=" + user.UserToken, "_blank" );
        }
      }

    render() {

        let hasSubmissions = this.state.submissions && this.state.submissions.length > 0;

        return (

            <div className="applications_summary shortlist">
              <div className="shortlisted_header">
                <div className="shortlist_title">Client Submissions<div onClick={this.reloadClicked} className="dashboard_sync_button"><FontAwesomeIcon icon={faSyncAlt}></FontAwesomeIcon></div></div>
                <div className="submissions_search"><SearchBox onSearch={this.doSearch} immediate={true} /></div>
                </div>
                <Spacer height={10} />
                {this.state.loadingList && <><Spacer height={10} /><div>Loading....</div></>}
                {(! this.state.loadingList && ! hasSubmissions) && <><Spacer height={10} /><div>No client submissions found</div></>}
                {(! this.state.loadingList && hasSubmissions) && <>

                        <DataTable className="shortlist_table"
                            progressPending={this.state.loading}
                            title=""
                            noHeader={true}
                            pagination={true}
                            columns={this.columns}
                            customStyles={this.customStyles}
                            data={this.state.submissions}
                            //noDataComponent={noSubmisisons}
                            //progressComponent={progress}
                            defaultSortField="LastUpdate"
                            defaultSortAsc={false}
                            conditionalRowStyles={this.conditionalRowStyles}
                            />
                        </>
                }
            </div>
        )
    }
}