
export default class Theme {

    static DENARIUS = "denarius";
    static UBS = "ubs"

    Current = () => {

        return Theme.DENARIUS;
       // return Theme.UBS;
    }

    UserGuide = () => {

        switch (this.Current() )
        {
            case Theme.DENARIUS:
                return "https://fst.fabtale.com/rbz/theme/den/Denarius%20Web%20Recruitment_UserGuide_2021.pdf";

            default: return "";
        }
    }
}