import * as types from './action-types';

// User class instance
export const replaceShortlisted = ( shortlisted ) => {
  return {
    type: types.SHORTLISTED_REPLACE_ALL,
    shortlisted
  };
}
  export const updateStatus = ( data ) => {
    return {
      type: types.SHORTLISTED_UPDATE_STATUS,
      data
    }
  }

  export const addNewSubmission = ( submission_id ) => {
    return {
      type: types.SHORTLISTED_ADDED_NEW_SUBMISSION,
      submission_id
    }
  }
