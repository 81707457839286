import React from 'react';
import styles from '../../css/login_form.module.css';
import '../../css/pretty-checkbox.css';
import Auth from '../classes/Auth';
import IOStatus from '../classes/IOStatus';
import Spacer from '../ui/Spacer';
import { LogInFormInput } from './LogInForm';

export default class CreateAccountForm extends React.Component {

    emailRef = null;
    passwordRef = null;
    nameRef = null;

    constructor( props )
    {
        super( props );

        this.state = { name:"", email: "", password: "", buttonEnabled: false, accountExists: false, emailErrorMsg: "", passwordErrorMsg: "", nameErrorMsg: "", isCreatingAccount: false, errorMsg: "", verificationRequired: false }

        this.emailRef = React.createRef();
        this.passwordRef = React.createRef();
        this.nameRef = React.createRef();

    }

    componentDidMount() {
        this.updateControls();
    }


    TextInputChanged = ( id, txt ) => {

        switch( id )
        {
            case "email":

                this.setState( { email: txt } );
                break;

            case "password":

                this.setState( { password: txt } );
                break;

            case "name":

                this.setState( { name: txt } );
                break;

            default: break;

        }
        this.updateControls();
    }

    updateControls = () => {


        let enabled = true;

        if ( this.state.email.length === 0 )
        {
            enabled = false;
        }

        if ( this.state.password.length === 0 )
        {
            enabled = false;
        }

        if ( this.state.name.length === 0 )
        {
            enabled = false;
        }
       this.setState( { buttonEnabled: enabled } );
    }


    isValidEmailAddress = (address) => {
        // simple email check, they will need to verify anyway
        return !! address.match(/.+@.+/);
    }

    doCreateAccount = async () => {



        let email = this.emailRef.current.GetText();
        if (! email || email.length === 0 )
        {
            this.setState( { emailErrorMsg: "Enter your email address", accountExists: false } );
            return;
        }

        if (! this.isValidEmailAddress( email ) )
        {
            this.setState( { emailErrorMsg: "Invalid email address", accountExists: false } );
            return;
        }

        let password = this.passwordRef.current.GetText();
        if (! password || password.length === 0 )
        {
            this.setState( { passwordErrorMsg: "Enter a password", accountExists: false } );
            return;
        }


        let name = this.nameRef.current.GetText();
        if (! name || name.length === 0 )
        {
            this.setState( { nameErrorMsg: "Enter your name" } );
            return;
        }
        this.setState(  { isCreatingAccount: true, errorMsg: "",accountExists: false } );

        let [status, response] = await Auth.CreateAccount( email, password, name )

        switch ( status )
        {
            case IOStatus.OK:

                // this.loggedIn( response );
                // console.log( response );
                this.setState(  { isCreatingAccount: false , errorMsg: "", accountExists: false, infoMsg: "Your account has been created, redirecting you to Sign in..." } );
                setTimeout( () => {
                    this.logIn();
                }, 4000 )

                break;

            case IOStatus.RESOURCE_EXISTS:

                this.setState(  { isCreatingAccount: false , errorMsg: "Account already exists", accountExists: true } );
                break;

            default:

                this.setState(  { isCreatingAccount: false , errorMsg: "Error contacting server, please try again" } );
                break;
        }

    }

    logIn = () => {

        if ( this.props.callback && this.props.callback.showLogInFromCreate )
        {
            this.props.callback.showLogInFromCreate( this.state.email, this.state.password );
        }
    }

    render() {

        let infoCls = styles.info;
        let infoMsg = this.state.infoMsg;

        let logInButtonCls = styles.button;
        if ( ! this.state.buttonEnabled )
        {
            logInButtonCls += " " + styles.disabled;
        }

        let emailLabel = "Email";
        let emailLabelCls = styles.inputLabel;

        if ( this.state.emailErrorMsg && this.state.emailErrorMsg.length > 0 )
        {
            emailLabel = this.state.emailErrorMsg;
            emailLabelCls += " " + styles.inputLabelError;
        }

        let passwordLabel = "Password";
        let passwordLabelCls = styles.inputLabel;

        if ( this.state.passwordErrorMsg && this.state.passwordErrorMsg.length > 0 )
        {
            passwordLabel = this.state.passwordErrorMsg;
            passwordLabelCls += " " + styles.inputLabelError;
        }

        let nameLabel = "Name";
        let nameLabelCls = styles.inputLabel;

        if ( this.state.nameErrorMsg && this.state.nameErrorMsg.length > 0 )
        {
            nameLabel = this.state.nameErrorMsg;
            nameLabelCls += " " + styles.inputLabelError;
        }

        if ( this.state.errorMsg.length > 0 )
        {
            infoCls += " " + styles.error;
            infoMsg = this.state.errorMsg;
        }


        let logInLabel = "CREATE ACCOUNT";
        if ( this.state.isCreatingAccount )
        {
            logInLabel = "Creating account..."
        }

        let showFooter = true;

        let signInButtonCls = styles.button + " " + styles.button_outline + " " + styles.button_inline;
        return (
               <div className={styles.holder}>
                <div className={styles.background}>
                    <div className={styles.title}>Create Account</div>
                    <div className={infoCls}>{infoMsg}</div>
                    {this.state.accountExists &&
                        <>
                            <Spacer height={10} />
                            <div onClick={this.logIn} className={signInButtonCls}>Sign in</div>
                        </>
                    }
                    <Spacer height={10} />

                    <div className={emailLabelCls}>{emailLabel}</div>
                    <LogInFormInput ref={this.emailRef}  type="email" placeholder="Your work email address" id="email" callback={this} focus={true} />
                    <div className={passwordLabelCls}>{passwordLabel}</div>
                    <LogInFormInput ref={this.passwordRef} type="password" placeholder="Password" id="password" newPassword={true} showPassword={true} callback={this}  />
                    <div className={nameLabelCls}>{nameLabel}</div>
                    <LogInFormInput ref={this.nameRef} type="text" placeholder="Name" id="name" callback={this}  />

                    <Spacer height={25} />
                    <div onClick={this.doCreateAccount} className={logInButtonCls}>{logInLabel}</div>
                    </div>
                    {showFooter && <div className={styles.footer}>
                        <div style={{"flexGrow": 1}} className={styles.button_quiet}  onClick={this.logIn}>Already have an account? <span style={{textDecoration: "underline"}}>Sign in</span></div>
                    </div>}
                </div>

        )
    }
}