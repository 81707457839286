import React, {  useReducer } from "react";

export const FirebaseContext = React.createContext(null);



const initialState = {};


const reducer = (state, action) => {

  //  const [mediaListState, mediaListDispatch] = useContext(MediaListContext);

    switch (action.type) {


      default: return state;
    }
}


export const FirebaseContextProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState );

    return (
      <FirebaseContext.Provider value={[state, dispatch]}>
        {props.children}
      </FirebaseContext.Provider>
    );
  };
