import React from 'react';
import PositionType from '../classes/PositionType'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faSyncAlt} from '@fortawesome/free-solid-svg-icons';
import ChangePositionTypeMenu from './ChangePositionTypeMenu'

export default class PositionTypePicker extends React.Component {


    constructor( props )
    {
        super( props );

        this.state = { open: this.props.open === true, type: this.props.type || PositionType.POSTED }
    }

    clicked = () => {

        this.setState( { open: ! this.state.open } );
    }

    reloadClicked = () => {
        this.setState( { open: false } );
        if ( this.props.callback.positionTypeReloadClicked )
        {
            this.props.callback.positionTypeReloadClicked();
        }
    }

    closePositionTypeMenu = () => {
        this.setState( { open: false } );
    }

    positionChangeTypePicked = (type) => {
        this.setState({ open: false, type: type } );
        if ( this.props.callback.positionTypeChanged )
        {
            this.props.callback.positionTypeChanged( type );
        }
    }
    render() {

        let label = PositionType.Label( this.state.type );
        let icon = faCaretDown;
        if ( this.state.open )
        {
            icon = faCaretUp;
        }
        return (
            <span>
                <div onClick={this.clicked} className="applications_summary_title drop_down_button">{label} <FontAwesomeIcon className="position_drop_down_button" icon={icon} ></FontAwesomeIcon></div><div onClick={this.reloadClicked} className="dashboard_sync_button"><FontAwesomeIcon icon={faSyncAlt}></FontAwesomeIcon></div>
                {this.state.open && <ChangePositionTypeMenu positionType={this.state.type} callback={this} />}
            </span>
        )
    }
}