import React from 'react';
import ApplicationsSummary, { ApplicationStatus, ClientSummary, PositionSummary } from '../classes/ApplicationsSummary';
import BioZing from '../classes/BioZing';
import IOStatus from '../classes/IOStatus';
import ApplicationPicker from './ApplicationPicker';

export default class ApplicationsList extends React.Component
{

    constructor( props )
    {
        super(props );
        this.state = { loadingApplicationsList: true, applicationsList:[], applicationsSummary: new ApplicationsSummary() };
    }

    componentDidMount() {
        this.loadApplicationsList({});

        this.loadApplicationsSummary({});
    }


    loadApplicationsSummary = async ( options ) => {

      // this.setState( { loadingApplicationsList: true, applicationsList:[] } )

      let bz = new BioZing();

      let res = await bz.LoadSummaryList( options );

      /*
      if ( res && res.result === IOStatus.OK )
      {
        ok = true;
      //  console.log( res );
        //applications = res.items;
      }
      */

      let al = new ApplicationsSummary();


      if (res.items && res.items.applications )
      {
        let applications = res.items.applications;
        let statuses = res.items.status;

        for( var i = 0; i < applications.length; i++ )
        {
          let application = applications[i];
          let c = new ClientSummary();
          c.Name = application.client;
          let client = al.AddClient( c );


        //  console.log( application );

          let p = new PositionSummary();
          p.ID = application.id;
          let position = client.AddPosition( p );
          position.PositionID = application.position_id;
          position.PostLink = application.post_link;
          position.Title = application.title;
          position.PostedDate = application.posted_date;
          position.Active = application.active ? true : false;
          position.Status = application.status;

          // loop through the status counts and add if provided
          if ( statuses )
          {
            statuses.forEach(element => {
              if ( element.application_link === position.PostLink )
              {
                switch( element.status )
                {
                  case ApplicationStatus.UNREAD:

                    position.Unread = element.count;
                    break;

                  case ApplicationStatus.DECLINED:

                    position.Declined = element.count;
                    break;

                  case ApplicationStatus.INTERVIEWING:

                    position.Interviewing = element.count;
                    break;

                  case ApplicationStatus.SHORTLISTED:

                    position.Shortlisted = element.count;
                    break;

                    default: break;
                }
              }
            });
          }
        }
      }

      /*
      // read all the applications, now add the status counts of each position
      if (res.items && res.items.status )
      {
        let status = res.status[i];

        for( var i = 0; i < status.length; i++ )
        {
          let st = status[i];
          let position =
        }

        }
      }
      */
      /*
      c.Name = "Wally";
      al.AddClient( c );

      let c2 = new Client();
      c2.Name = "Pepsi Australia";
      al.AddClient( c2 );

      let c3 = new Client();
      c3.Name = "Wally";
      al.AddClient( c3 );
*/
    //  console.log( al );

      this.setState( { applicationsSummary:  al } );

      /*
      store.dispatch( applicationsActions.addClient( "Wally") )
      store.dispatch( applicationsActions.addClient( "Pepsi Australia") );
      store.dispatch( applicationsActions.addClient( "Wally") )
      */

      // this.setState( { loadingApplicationsList: false, applicationsList: applications } )
    }



    loadApplicationsList = async ( options ) => {

        this.setState( { loadingApplicationsList: true, applicationsList:[] } )

        let bz = new BioZing();

        let res = await bz.LoadList( options );
        //let ok = false;
        let applications = [];
        if ( res && res.result === IOStatus.OK )
        {
          // ok = true;
          applications = res.items;
        }

        this.setState( { loadingApplicationsList: false, applicationsList: applications } )
      //  console.log( applications );
      }


      SearchActiveChanged = ( active ) => {
        this.loadApplicationsList( { active: active } )
      }

    render() {
        return (

            <div className="applications_list">
            <ApplicationPicker applicationsList={this.state.applicationsList} callback={this} />
            </div>
        )
    }
}