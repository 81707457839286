import React from 'react';
import Notifications from 'react-notify-toast';
import * as userActions from '../actions/user-actions';
import '../css/App.css';
import { store } from '../index';
import Auth from './classes/Auth';
import AuthStatus from './classes/AuthStatus';
import Globals from './classes/Globals';
import IOStatus from './classes/IOStatus';
//import Firebase from './Firebase/Firebase';
import User from './classes/User';
import AppHeader from './components/AppHeader';
import HomePage from './components/HomePage';
import RecoverAccountForm from './components/RecoverAccountForm';
import SignedInPage from './components/SignedInPage';




const queryString = require('query-string');

export default class App extends React.Component
{

  passwordReset = "";

  constructor ( props )
  {
    super( props );


    let showPasswordReset = false;
    let showCreateAccount = false;

    const parsed = queryString.parse(window.location.search);
    if ( parsed && parsed.pwr && parsed.pwr.length > 0 )
    {
      showPasswordReset = true;
      this.passwordReset = parsed.pwr;
    }

    if ( parsed && parsed.action && parsed.action.length > 0 )
    {
      if ( parsed.action === "signup" )
      {
        showCreateAccount = true;
      }
    }

    this.state = { authStatus: AuthStatus.CHECKING, applicationsList: [], showPasswordReset: showPasswordReset, showCreateAccount: showCreateAccount }



  }

  componentDidMount() {

    if( ! this.state.showPasswordReset )
    {
      this.checkForSavedLogin();
    }

    if ( this.state.showCreateAccount )
    {
      try {
        let url = window.location.protocol + '//' + window.location.host + window.location.pathname;
        window.history.replaceState({}, '', url );
      } catch( e )
      {

      }
    }
   }


   checkForSavedLogin = async () => {

      let token = User.LoadSavedToken();
      if ( token && token.length > 0 )
      {
        // has token, try to log in with it
        let [status, result] = await Auth.LoginWithToken( token )
        switch ( status )
        {
            case IOStatus.OK:

                this.UserLoggedIn( result, true );
                return;

            default: break;
        }
      }
      this.checkAuth();
   }

   UserLoggedIn = ( data, keepLoggedIn ) => {

    if( this.state.showPasswordReset )
    {
      return;
    }

    let newUser = new User();

    newUser.Name = data.name;
    newUser.IsAnonymous = false
    newUser.Identifier = data.identifier;
    newUser.Email = data.email;
    newUser.EmailVerified = (data.verified === true);
    newUser.UserToken = data.token;

    User.SaveToken( data.token, keepLoggedIn );
    newUser.FirebaseToken = data.firebaseToken;
    newUser.FirebaseUid = data.identifier;

    store.dispatch( userActions.replaceUser( newUser) );

    if ( newUser.FirebaseToken && newUser.FirebaseToken.length > 0 )
    {
      Globals.FirebaseInstance.LogInWithCustomToken(newUser.FirebaseToken);
    }

    this.AuthStatusChanged();
  }

   AuthStatusChanged = () => {
    this.checkAuth();
   }




   checkAuth = async () => {

    if( this.state.showPasswordReset )
    {
      return;
    }

    let user = User.CurrentUser();
    if  (user && user.LoggedIn() )
    {
      this.setState( { authStatus: AuthStatus.SIGNED_IN } )

      return;
    }

    this.setState( { authStatus: AuthStatus.NOT_SIGNED_IN } )

  }


  AuthStatusChanged = () => {
    this.checkAuth();
  }


  SignOut = async () => {

    await Auth.SignOut();

    this.AuthStatusChanged();

  }

  render() {


      // console.log( this.state.applicationsList );

      if( this.state.showPasswordReset )
      {
        return (
         <div className="login_content">
            <div className="login_logo"></div>
            <RecoverAccountForm pwr={this.passwordReset} />
            </div>
        )
      }

          return (
            <div className="app">
              <AppHeader title="Recruiter Dashboard" callback={this} authStatus={this.state.authStatus}></AppHeader>
              {this.state.authStatus === AuthStatus.SIGNED_IN && <SignedInPage  callback={this} />}
              {this.state.authStatus === AuthStatus.NOT_SIGNED_IN && <HomePage callback={this} showCreateAccount={this.state.showCreateAccount} /> }
              {this.state.authStatus === AuthStatus.CHECKING && <div className="login_content"><div className="content_loading_msg">Loading...</div></div>}
              <Notifications />
            </div>
          );
}
}
