import React from 'react';
import styles from '../../css/login_form.module.css';
import '../../css/pretty-checkbox.css';
import Auth from '../classes/Auth';
import IOStatus from '../classes/IOStatus';
import Spacer from '../ui/Spacer';
import { LogInFormInput } from './LogInForm';

export default class RecoverAccountForm extends React.Component {

    emailRef = null;
    passwordRef = null;

    defaultInfoMsg = "Please enter your account email address and a new password";

    constructor( props )
    {
        super( props );

        this.state = { name:"", buttonEnabled: false, redirectUrl: "", isResetting: false, email: "", password: "",  emailErrorMsg: "",   errorMsg: "", infoMsg: this.defaultInfoMsg }
        this.emailRef = React.createRef();
        this.passwordRef = React.createRef();
    }

    componentDidMount() {
        this.updateControls();
    }

    TextInputChanged = ( id, txt ) => {

        switch( id )
        {
            case "email":

                this.setState( { email: txt } );
                break;

            case "password":

                this.setState( { password: txt } );
                break;

            default: break;
        }

        this.updateControls();
    }

    backToHomePage = () => {

        let url = window.location.href;
        let parts = url.split("?" );
        let homePage = parts[0];

        window.location.href = homePage;
    }

    doResetPassword = async () => {
        let email = this.emailRef.current.GetText();
        if (! email || email.length === 0 )
        {
            this.setState( { emailErrorMsg: "Enter your email address" } );
            return;
        }

        if (! this.isValidEmailAddress( email ) )
        {
            this.setState( { emailErrorMsg: "Invalid email address" } );
            return;
        }

        let password = this.passwordRef.current.GetText();
        if (! password || password.length === 0 )
        {
            this.setState( { passwordErrorMsg: "Enter a password", accountExists: false } );
            return;
        }

        this.setState( { isResetting: true, errorMsg: "", infoMsg: this.defaultInfoMsg } );

        let [status, response] = await Auth.ResetPassword( email, this.props.pwr, password )
        switch ( status )
        {
            case IOStatus.OK:

                // this.loggedIn( response );
                this.setState(  { isResetting: false , errorMsg: "",  infoMsg: "Your password has been reset, please log in now." } );
                setTimeout( () => {
                    this.backToHomePage();
                }, 3000 );
                break;

                case IOStatus.NOT_FOUND:

                    // this.loggedIn( response );
                    this.setState(  { isResetting: false , errorMsg: "Reset link has expired or the account email is not correct", infoMsg: "" } );
                    break;


            default:

                this.setState(  { isResetting: false , errorMsg: "Error contacting server, please try again" } );
                break;
        }

    }


    updateControls = () => {


        let enabled = true;

        if ( this.state.email.length === 0 )
        {
            enabled = false;
        }

        if ( this.state.password.length === 0 )
        {
            enabled = false;
        }

       this.setState( { buttonEnabled: enabled } );
    }


    isValidEmailAddress = (address) => {
        // simple email check, they will need to verify anyway
        return !! address.match(/.+@.+/);
    }

    logIn = () => {

        if ( this.props.callback && this.props.callback.showLogInFromRecover )
        {
            this.props.callback.showLogInFromRecover();
        }
    }

    render() {
        let infoCls = styles.info;
        let infoMsg = this.state.infoMsg;

        let logInButtonCls = styles.button;
        if ( ! this.state.buttonEnabled )
        {
            logInButtonCls += " " + styles.disabled;
        }

        let emailLabel = "Email";
        let emailLabelCls = styles.inputLabel;

        if ( this.state.emailErrorMsg && this.state.emailErrorMsg.length > 0 )
        {
            emailLabel = this.state.emailErrorMsg;
            emailLabelCls += " " + styles.inputLabelError;
        }

        let passwordLabel = "Password";
        let passwordLabelCls = styles.inputLabel;

        if ( this.state.passwordErrorMsg && this.state.passwordErrorMsg.length > 0 )
        {
            passwordLabel = this.state.passwordErrorMsg;
            passwordLabelCls += " " + styles.inputLabelError;
        }

        if ( this.state.errorMsg.length > 0 )
        {
            infoCls += " " + styles.error;
            infoMsg = this.state.errorMsg;
        }


        let logInLabel = "RESET PASSWORD";
        if ( this.state.isResetting )
        {
            logInLabel = "Resetting..."
        }



        return (
            <div className={styles.holder}>
             <div className={styles.background}>
                 <div className={styles.title}>Recover Account</div>
                 <div className={infoCls}>{infoMsg}</div>
                 <Spacer height={10} />

                 <div className={emailLabelCls}>{emailLabel}</div>
                 <LogInFormInput ref={this.emailRef}  type="email" placeholder="Your account email address" id="email" callback={this} focus={true} />
                 <div className={passwordLabelCls}>{passwordLabel}</div>
                    <LogInFormInput ref={this.passwordRef} type="password" placeholder="Password" id="password" showPassword={true} callback={this}  />

                 <Spacer height={25} />
                    <div onClick={this.doResetPassword} className={logInButtonCls}>{logInLabel}</div>
                    </div>
                 </div>

        )
    }
}
