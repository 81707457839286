import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PositionType from '../classes/PositionType';


export default class ChangePositionTypeMenu extends React.Component{




    closePositionTypeMenu = () => {

        if ( this.props.callback && this.props.callback.closePositionTypeMenu )
        {
            this.props.callback.closePositionTypeMenu();
        }
    }

    positionChangeTypePicked = (type) => {

        if ( this.props.callback && this.props.callback.positionChangeTypePicked )
        {
            this.props.callback.positionChangeTypePicked(type);
        }
    }



    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyDown, false );
    }

    handleKeyDown = ( event ) => {
        if ( event && event.keyCode === 27 )
        {
            this.closePositionTypeMenu();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown',  this.handleKeyDown, false );
    }

    render() {

        let showPosted = true;
        let showArchived = true;
        let showDraft = true;

        switch( this.props.positionType )
        {
            case PositionType.POSTED:

                showPosted = false;
                break;

            case PositionType.DRAFT:

                showDraft = false;
                break;

            case PositionType.ARCHIVED:

                showArchived = false;
                break;

            default: break;
        }

        return (
            <div style={{position: "relative"}} >
            <div className="position_type_dropdown_mask" onClick={this.closePositionTypeMenu}></div>

            <div className="position_type_dropdown">
                <div className="position_type_dropdown_title"  onClick={this.closePositionTypeMenu}><div className="position_type_dropdown_close"><FontAwesomeIcon icon={faTimes} /></div></div>
                {showPosted && <div onClick={() => {this.positionChangeTypePicked(PositionType.POSTED)}} className="position_type_dropdown_item">{PositionType.Label(PositionType.POSTED)}</div>}
                {showDraft && <div onClick={() => {this.positionChangeTypePicked(PositionType.DRAFT)}} className="position_type_dropdown_item">Draft Positions</div>}
                {showArchived && <div onClick={() => {this.positionChangeTypePicked(PositionType.ARCHIVED)}} className="position_type_dropdown_item">Archived Positions</div>}
            </div>
            </div>
        )
    }
}