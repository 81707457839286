import React from 'react';
import styles from '../../css/login_form.module.css';
import '../../css/pretty-checkbox.css';
import Auth from '../classes/Auth';
import IOStatus from '../classes/IOStatus';
import Spacer from '../ui/Spacer';
import { LogInFormInput } from './LogInForm';

export default class VerifyCodeForm extends React.Component {

    codeRef = null;

    constructor( props )
    {
        super( props );

        this.state =  { codeErrorMsg: "", isVerifying: false, code: "", buttonEnabled:false, resendCodeLabel: "Resend code" };
        this.codeRef = React.createRef();
    }

    componentDidMount() {
        this.codeRef.current.Focus();
    }

    verifiedEmail = ( result ) => {

        if ( this.props.callback && this.props.callback.VerifiedEmail )
        {
            this.props.callback.VerifiedEmail( result, this.props.keepLoggedIn );
        }

    }

    doVerify = async () => {
        let code = this.codeRef.current.GetText();
        if (! code || code.length === 0 )
        {
            this.setState( { codeErrorMsg: "Enter the verification code" } );
            return;
        }

        this.setState(  { isVerifying: true, errorMsg: "" } );

        let [status, response] = await Auth.VerifyEmail( this.props.email, code )
        switch ( status )
        {
            case IOStatus.OK:

                this.verifiedEmail( response );
                break;

            default:

                if  (response && response.error === IOStatus.VERIFICATION_FAILED )
                {
                    this.setState( { codeErrorMsg: "Verification failed", isVerifying: false } )
                }
                else
                {
                    this.setState(  { isVerifying: false , codeErrorMsg: "Server error, please try again" } );
                }
                break;
        }
    }


    TextInputChanged = ( id, txt ) => {

        switch( id )
        {
            case "code":

                //this.setState( { code: txt } );
                break;

            default: break;


        }
        this.updateControls();
    }

    updateControls = () => {

        let code = this.codeRef.current.GetText();
        this.setState( { buttonEnabled: code && code.length === 6 } )
    }

    TextInputKeyUp = ( id, keyCode ) => {
       // console.log( id, keyCode );
       //
       switch( id )
       {
            case "code":

                if ( keyCode === 13 && this.state.buttonEnabled )
                {
                    this.doVerify();
                }
                break;

        default: break;
       }
    }

    resendCode = async () => {

        // code return success if account exists or not, only error if is server error
        this.setState( { isVerifying: false, resendCodeLabel: "Resending..."})
        let [status, response] = await Auth.ResendVerificationCode( this.props.email )
        switch ( status )
        {
            case IOStatus.OK:

                this.setState( { resendCodeLabel: "Code resent!"})
                setTimeout( () => {
                    this.setState( { resendCodeLabel: "Resend code"})
                }, 2500 );
                break;

            default:

                this.setState( { resendCodeLabel: "Error sending code"})
                setTimeout( () => {
                    this.setState( { resendCodeLabel: "Resend code"})
                }, 3500 );
                break;
        }
    }

    cancel = () => {

        if ( this.props.callback && this.props.callback.CloseVerificationForm )
        {
            this.props.callback.CloseVerificationForm();
        }
    }

    render() {

        let infoMsg = <>A verification code has been sent to <span style={{fontWeight:600}}>{this.props.email}</span><br />Please check your inbox and spam folders.</>
        let verifyButtonCls = styles.button;
        if ( ! this.state.buttonEnabled )
        {
            verifyButtonCls += " " + styles.disabled;
        }

        let infoCls = styles.info;

        let codeLabel = "Verification Code";
        let codeLabelCls = styles.inputLabel;

        if ( this.state.codeErrorMsg && this.state.codeErrorMsg.length > 0 )
        {
            codeLabel = this.state.codeErrorMsg;
            codeLabelCls += " " + styles.inputLabelError;
        }

        let verifyLabel = "VERIFY";
        if ( this.state.isVerifying )
        {
            verifyLabel = "Verifying..."
        }

        return (

            <div className={styles.holder}>
                <div className={styles.background}>
                    <div className={styles.title}>Verify Email</div>
                    <div className={infoCls}>{infoMsg}</div>
                    <Spacer height={10} />
                    <div className={codeLabelCls}>{codeLabel}</div>
                    <LogInFormInput ref={this.codeRef} type="text" placeholder="6 digit code" id="code" callback={this} />
                    <Spacer height={25} />
                    <div onClick={this.doVerify} className={verifyButtonCls}>{verifyLabel}</div>
                </div>
                <div className={styles.footer}>
                    <div className={styles.button_quiet}  onClick={this.resendCode}>{this.state.resendCodeLabel}</div>
                    <div className={styles.divider}>|</div>
                    <div className={styles.button_quiet} onClick={this.cancel}>Cancel</div>
                </div>
            </div>
        )
    }

}
