import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Application from '../classes/Application';
import ApplicationsSummary, { ApplicationStatus, ClientSummary, PositionSummary } from '../classes/ApplicationsSummary';
import BioZing from '../classes/BioZing';
import Broadcast from '../classes/Broadcast';
import Globals from '../classes/Globals';
import PositionType from '../classes/PositionType';
import Utils from '../classes/Utils';
import Spacer from '../ui/Spacer';
import Span from '../ui/Span';
import ApplicationDisplay from './ApplicationDisplay';
import PositionTypePicker from './PositionTypePicker';
import SubmissionsDisplay from './SubmissionsDisplay';
import SubmissionStatusSlug from './SubmissionStatusSlug';


export default class ApplicationsSummaryDisplay extends React.Component
{

    constructor( props )
    {
        super(props );
        this.state = { loadingApplicationsList: true, applicationsSummary: new ApplicationsSummary(), positionType: PositionType.POSTED };
    }

    componentDidMount() {
          this.loadApplicationsSummary({});

          let broadcast = Globals.Broadcast.Channel();
          broadcast.addEventListener('message', this.handleBroadcastMessage);

    }


  // received msg from another tab
  handleBroadcastMessage = ( msg ) => {

    if ( ! msg )
    {
      return;
    }

    switch( msg.type )
    {

      case Broadcast.APPLICATION_PUBLISHED:
      case Broadcast.APPLICATION_SAVED:

      /*
        if ( this.state.positionType === PositionType.POSTED )
        {
            this.loadApplicationsSummary( {} );
        }
        break;

       case Broadcast.APPLICATION_SAVED:

        if ( this.state.positionType === PositionType.DRAFT )
        {
            this.loadApplicationsSummary( { draft: 1 } );
        }
        */
        this.positionTypeReloadClicked();
        break;

        default: break;
    }
}




    loadApplicationsSummary = async ( options ) => {

      // this.setState( { loadingApplicationsList: true, applicationsList:[] } )

      let bz = new BioZing();

      let res = await bz.LoadSummaryList( options );
      /*
      let ok = false;
      let applications = [];
      if ( res && res.result === IOStatus.OK )
      {
        ok = true;
      }
      */

      let al = new ApplicationsSummary();


      if (res.items && res.items.applications )
      {
        let applications = res.items.applications;
        let statuses = res.items.status;

        for( var i = 0; i < applications.length; i++ )
        {
          let application = applications[i];
          let c = new ClientSummary();
          c.Name = application.client;
          let client = al.AddClient( c );

          let p = new PositionSummary();
          p.ID = application.id;
          let position = client.AddPosition( p );
          position.PositionID = application.position_id;
          position.PostLink = application.post_link;
          position.Title = application.title;
          position.PostedDate = application.posted_date;
          position.Active = application.active ? true : false;
          position.Status = application.status;

          // loop through the status counts and add if provided
          if ( statuses )
          {
            statuses.forEach(element => {
              if ( element.application_link === position.PostLink )
              {
                switch( element.status )
                {
                  case ApplicationStatus.UNREAD:

                    position.Unread = element.count;
                    break;

                  case ApplicationStatus.DECLINED:

                    position.Declined = element.count;
                    break;

                  case ApplicationStatus.INTERVIEWING:

                    position.Interviewing = element.count;
                    break;

                  case ApplicationStatus.SHORTLISTED:

                    position.Shortlisted = element.count;
                    break;

                    default: break;
                }
              }
            });
          }
        }
      }

      this.setState( { loadingApplicationsList: false, applicationsSummary:  al } );


      // this.setState( { loadingApplicationsList: false, applicationsList: applications } )
    }

    positionTypeChanged = ( type ) => {
        this.setState( { positionType: type, loadingApplicationsList: true } );
        switch( type )
        {
            case PositionType.POSTED:
                this.loadApplicationsSummary( {} );
                break;

            case PositionType.ARCHIVED:
                this.loadApplicationsSummary( { active: 0 } );
                break;

            case PositionType.DRAFT:
                this.loadApplicationsSummary( { draft: 1 } );
                break;

                default: break;
        }

    }

    positionTypeReloadClicked = () => {

        this.positionTypeChanged( this.state.positionType );
    }


    ApplicationUpdated = ( application, updates, client ) =>{
        // can't update state directly, need to copy list and change position details manually
        let al = new ApplicationsSummary();

        for ( var i = 0; i < this.state.applicationsSummary.Clients.length; i++ )
        {
            let cl = this.state.applicationsSummary.Clients[i];
            if ( cl.Name !== client.Name )
            {
                al.AddClient( cl );
            }
            else
            {
                let clcopy = new ClientSummary();
                clcopy.Name = cl.Name;

                for ( var j = 0; j < cl.Positions.length; j++ )
                {
                    let pos = cl.Positions[j];
                    if ( pos.ID === application.ID )
                    {
                        let poscpy = PositionSummary.Copy( pos );
                        poscpy.Active = updates.active;
                        clcopy.AddPosition( poscpy );
                    }
                    else
                    {
                        clcopy.AddPosition( pos );
                    }
                }
                al.AddClient(clcopy )
            }
        }

        this.setState( { applicationsSummary:  al } );
    }

    render() {

        let loading = this.state.loadingApplicationsList;

        return (

            <div className="applications_summary">
                <PositionTypePicker type={this.state.positionType} callback={this} />
                <Spacer height={10} />
                {loading &&
                    <div>Loading...</div>
                }

                 {! loading && this.state.applicationsSummary.Clients.map(function(client, index) {
                    return (
                        <ApplicationSummaryDisplay key={index} client={client} callback={this} />
                    )
                }, this )}

            </div>
        )
    }
}

export class ApplicationSummaryDisplay extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = { collapsed: ! (this.props.collapsed === false ) }
    }

    headerClicked = () => {
        this.setState( { collapsed: ! this.state.collapsed } );
    }

    ApplicationUpdated = (application, updates ) =>{
            if ( this.props.callback && this.props.callback.ApplicationUpdated )
            {
                this.props.callback.ApplicationUpdated(  application, updates, this.props.client );
            }
    }


    render() {

        let cls = "application_summary";
        if ( this.state.collapsed )
        {
            cls += " collapsed";
        }

        return (

            <div className={cls}>
                <ApplicationSummaryHeader client={this.props.client} callback={this} collapsed={this.state.collapsed} />
                {! this.state.collapsed && <span>
                    {this.props.client.Positions.map( function(position, index) {
                        return (
                            <PositionSummaryDisplay key={index} position={position} callback={this} />
                        )
                    }, this )}
                    </span>
                }
            </div>
        )
    }
}



export class ApplicationSummaryHeader extends React.Component {


    render() {

        let icon = faChevronUp;
        if  (this.props.collapsed)
        {
            icon = faChevronDown;
        }

        let count = this.props.client.TotalUnread();

        return (

            <div className="applications_summary_header" onClick={this.props.callback.headerClicked}>
                <div className="applications_summary_name">{this.props.client.Name}</div>
                <SubmissionStatusSlug count={count} type={ApplicationStatus.UNREAD} />
                <Span width={20} />
                <div className="application_summary_open_button"><FontAwesomeIcon icon={icon} ></FontAwesomeIcon></div>
            </div>
        )
    }
}



export class PositionSummaryHeader extends React.Component {


    render() {

        let icon = faChevronUp;
        if  (this.props.collapsed)
        {
            icon = faChevronDown;
        }

        let count = this.props.position.Unread;
        let name = this.props.position.DisplayTitle();

        return (

            <div className="position_summary_header" onClick={this.props.callback.headerClicked}>
                <div className="position_summary_name">{name}</div>
                <SubmissionStatusSlug count={count} type={ApplicationStatus.UNREAD} />
                <Span width={20} />
                <div className="application_summary_open_button"><FontAwesomeIcon icon={icon} ></FontAwesomeIcon></div>
            </div>
        )
    }
}


export class PositionSummaryDisplay extends React.Component {

    /*
    dummyApplication = {
        Active: true,
        Archived: 10,
        Client: "",
        ID: 35,
        Interviewing: 2,
        Link: "qrqy-bsvh-zzsg-fwvh",
        PositionID: "SK 12",
        Shortlisted: 3,
        Status: "Posted",
        Submissions: 103,
        Title: "SK 12 - Brewer"
    }
    */

    constructor( props )
    {
        super( props );
        this.state = { collapsed: ! (this.props.collapsed === false ) }
    }

    headerClicked = () => {
        this.setState( { collapsed: ! this.state.collapsed } );
    }

    ApplicationUpdated = ( application, updates ) =>{
        if ( this.props.callback && this.props.callback.ApplicationUpdated )
        {
            this.props.callback.ApplicationUpdated( application, updates );
        }

    }

    render() {

        let cls = "position_summary";
        if ( this.state.collapsed )
        {
            cls += " collapsed";
        }

        let app = new Application();

        let pos = this.props.position;

         app.ID = pos.ID;
            app.Client = "";
            app.PositionID = pos.PositionID;
            app.Active = pos.Active;
            app.Title = pos.Title;
            app.Submissions = pos.Unread + pos.Declined + pos.Interviewing + pos.Shortlisted;
            app.Status = Utils.HasString( pos.PostLink ) ? "Posted" : "Draft";
            app.Shortlisted = pos.Shortlisted;
            app.Declined = pos.Declined;
            app.Interviewing = pos.Interviewing;
            app.Link = pos.PostLink;

        return (

            <div className={cls}>
                <PositionSummaryHeader position={this.props.position} callback={this} collapsed={this.state.collapsed} />
                {! this.state.collapsed &&
                    <div className="application_summary_display">

                        {(this.props.position.PostLink && this.props.position.PostLink.length > 0) &&
                            <SubmissionsDisplay post_link={this.props.position.PostLink} />
                        }
                        <ApplicationDisplay application={app} callback={this} />
                    </div>
                }
            </div>
        )
    }
}
