export default class Storage
{

    // attach one to app object or window, so that local storage check is only done once.
    // if localStorage is not available, functions are ignored, nothing is stored

    localStorageAvailable = undefined;


	HasLocalStorage = function()
	{

		if ( this.localStorageAvailable !== undefined )
		{
			return this.localStorageAvailable;
        }

		var test = 'testForLocalStorage';
    	try {
        	localStorage.setItem(test, test);
        	localStorage.removeItem(test);
        	this.localStorageAvailable = true;
        	return true;
    	}
    	catch(e)
    	{
    		this.localStorageAvailable = false;
        	return false;
    	}
    }

	StoreLocal( key, value )
    {
    	if ( this.HasLocalStorage() )
    	{
    		localStorage.setItem(key, value);
    	}
    }

    RemoveLocal( key )
    {
        if ( this.HasLocalStorage() )
    	{
    		localStorage.removeItem( key );
    	}
    }

    LoadLocal( key )
    {
    	if ( this.HasLocalStorage() )
    	{
    		return localStorage.getItem(key);
    	}

    	return null;
	}

	StoreSession( key, value )
    {
    	if ( this.HasLocalStorage() )
    	{
    		sessionStorage.setItem(key, value);
    	}
    }

    RemoveSession( key )
    {
        if ( this.HasLocalStorage() )
    	{
    		sessionStorage.removeItem( key );
    	}
    }

    LoadSession( key )
    {
    	if ( this.HasLocalStorage() )
    	{
    		return sessionStorage.getItem(key);
    	}

    	return null;
    }
}
