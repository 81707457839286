
export default class Submission {

    VerificationCode = "";
    ApplicationLink = "";
    Token = "";
    ResumeUrl = "";
    CoverLetterUrl = "";
    VideoUrl = "";
    Answers = [];

    CandidateEmail = "";
    CandidateName = ""
    CandidatePhone = "";
    DateSubmitted = 0;
    Status = 0;
    ID = 0;

    InterviewDate = null;
    InterviewStatus = 0;
    InterviewID = "";
}