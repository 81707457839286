
export default class Application {

    ID = "";
    Client = "";
    PositionID = "";
    Active = true;
    Title = "";
    Submissions = 0;
    Status = "";
    Shortlisted = 0;
    Declined = 0;
    Interviewing = 0;
    Link = "";


    static Copy = ( src ) => {

        if ( ! src ) return src;

         let dest = Object.assign( new Application(), src );

        return dest;
    }

}