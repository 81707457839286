import React from 'react';
import styles from '../../css/login_form.module.css';
import '../../css/pretty-checkbox.css';
import Auth from '../classes/Auth';
import IOStatus from '../classes/IOStatus';
import Spacer from '../ui/Spacer';
import { LogInFormInput } from './LogInForm';

export default class ResetPasswordForm extends React.Component {

    emailRef = null;

    constructor( props )
    {
        super( props );

        this.state = { name:"", buttonEnabled: false, isResetting: false, email: "",  emailErrorMsg: "",   errorMsg: "", infoMsg: "Please enter your account email address" }
        this.emailRef = React.createRef();
    }

    componentDidMount() {
        this.updateControls();
    }

    TextInputChanged = ( id, txt ) => {

        switch( id )
        {
            case "email":

                this.setState( { email: txt } );
                break;

            default: break;

        }
        this.updateControls();
    }

    doResetPassword = async () => {
        let email = this.emailRef.current.GetText();
        if (! email || email.length === 0 )
        {
            this.setState( { emailErrorMsg: "Enter your email address" } );
            return;
        }

        if (! this.isValidEmailAddress( email ) )
        {
            this.setState( { emailErrorMsg: "Invalid email address" } );
            return;
        }

        this.setState( { isResetting: true } );

        let [status, response] = await Auth.InitAccountRecover( email )
        switch ( status )
        {
            case IOStatus.OK:

                // this.loggedIn( response );
                this.setState(  { isResetting: false , errorMsg: "", accountExists: false, infoMsg: "An email has been sent to your address, please follow the instructions." } );
                break;


            default:

                this.setState(  { isResetting: false , errorMsg: "Error contacting server, please try again" } );
                break;
        }

    }


    updateControls = () => {


        let enabled = true;

        if ( this.state.email.length === 0 )
        {
            enabled = false;
        }

       this.setState( { buttonEnabled: enabled } );
    }


    isValidEmailAddress = (address) => {
        // simple email check, they will need to verify anyway
        return !! address.match(/.+@.+/);
    }

    logIn = () => {

        if ( this.props.callback && this.props.callback.showLogInFromRecover )
        {
            this.props.callback.showLogInFromRecover();
        }
    }

    render() {
        let infoCls = styles.info;
        let infoMsg = this.state.infoMsg;

        let logInButtonCls = styles.button;
        if ( ! this.state.buttonEnabled )
        {
            logInButtonCls += " " + styles.disabled;
        }

        let emailLabel = "Email";
        let emailLabelCls = styles.inputLabel;

        if ( this.state.emailErrorMsg && this.state.emailErrorMsg.length > 0 )
        {
            emailLabel = this.state.emailErrorMsg;
            emailLabelCls += " " + styles.inputLabelError;
        }

        if ( this.state.errorMsg.length > 0 )
        {
            infoCls += " " + styles.error;
            infoMsg = this.state.errorMsg;
        }


        let logInLabel = "RESET PASSWORD";
        if ( this.state.isResetting )
        {
            logInLabel = "Resetting..."
        }

        let showFooter = true;


        return (
            <div className={styles.holder}>
             <div className={styles.background}>
                 <div className={styles.title}>Reset Password</div>
                 <div className={infoCls}>{infoMsg}</div>
                 <Spacer height={10} />

                 <div className={emailLabelCls}>{emailLabel}</div>
                 <LogInFormInput ref={this.emailRef}  type="email" placeholder="Your account email address" id="email" callback={this} focus={true} />

                 <Spacer height={25} />
                    <div onClick={this.doResetPassword} className={logInButtonCls}>{logInLabel}</div>
                    </div>

                    {showFooter && <div className={styles.footer}>
                        <div style={{"flexGrow": 1}} className={styles.button_quiet}  onClick={this.logIn}>Back to <span style={{textDecoration: "underline"}}>Sign in</span></div>
                    </div>}
                 </div>

        )
    }
}