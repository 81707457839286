import * as types from '../actions/action-types';
import User from "../js/classes/User"

const initialState = {
    user: new User()
}

export default (state = initialState, action) => {

  switch (action.type) {
      case types.USER_REPLACE:

           // console.log( "action: ", action );
            return Object.assign({}, state, { user: action.user } );

      case types.USER_UPDATE:

          // console.log( "action: ", action );
          return Object.assign({}, state, { user: Object.assign( state.user, action.fields ) } );


    default:
      return state;
  }
};