import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import copy from 'copy-to-clipboard';
import React from 'react';


export default class CopyToClipboardButton extends React.Component
 {

    // time to show copied message
    timeout = 1500;

    constructor( props )
    {
        super( props )

        this.state = { copied: false }
    }

    copyToClipboard = ( ) =>
    {
        let txt = this.props.text;

        copy(txt, {
            debug: false,
            message: 'Press #{key} to copy',
          });
        this.setState( { copied: true  } );
        setTimeout( () => {
            this.setState( { copied: false } );
        }, this.timeout )
    }



    render() {



        return (

            <div className="copy_button" title={this.props.title} onClick={this.copyToClipboard}><FontAwesomeIcon icon={faCopy}></FontAwesomeIcon>
            {this.state.copied &&
                <div className="copy_button_copied_msg">{this.props.copiedMsg}</div>
            }

            </div>

        )
    }
 }