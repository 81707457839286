// user
export const USER_REPLACE = 'USER_REPLACE';
export const USER_UPDATE = "USER_UPDATE";


// applications
export const APPLICATION_ADD_CLIENT = "APPLICATION_ADD_CLIENT";


// shortlisted submissions
export const SHORTLISTED_REPLACE_ALL = "SHORTLISTED_REPLACE_ALL";
export const SHORTLISTED_UPDATE_STATUS = "SHORTLISTED_UPDATE_STATUS";
export const SHORTLISTED_ADDED_NEW_SUBMISSION = "SHORTLISTED_ADDED_NEW_SUBMISSION";
