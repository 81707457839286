import Utils from "./Utils";

export default class Urls
{

    API_BASE = "https://api.recruitment.biozing.com/t8/";

    constructor()
    {
        if ( Utils.DevMode() )
        {
           // this.API_BASE =  "https://localhost/f/zing/recruitment/api/public/t5/";
           // this.API_BASE =  "https://local.telezing.com/f/zing/recruitment/api/public/t8/";
        }
    }

    LoginUrl = () => {

        return this.API_BASE + "user/login"

    }

    LoadSubmissionUrl = () => {
        return this.API_BASE + "user/submission/"
    }


    VerifyEmailUrl = () => {

        return this.API_BASE + "user/verify"
    }

    ResendVerificationCodeUrl = () => {

        return this.API_BASE + "user/resend"
    }

    CreateAccountUrl = () => {

        return this.API_BASE + "account"

    }

    ApplicationDisplayUrl = () => {
        // add application link to create url for candidate to show application
        // must be full url suitable for posting to web sites, emails, etc
        if ( Utils.DevMode() )
        {
           return "http://localhost:3002/";
        }
        else
        {
            return  this.getWebBase() + "job/";
        }
    }

    ViewSubmissionUrl = () => {
        // add submission id to create url to view submission
        if ( Utils.DevMode() )
        {
            return "http://localhost:3003/submission/";
           //return "https://recruitment.biozing.com/submission/";
        }
        else
        {
            return this.getWebBase() + "submission/";
        }
    }

    getWebBase = () => {

        let host = window.location.hostname;
        if ( host === "local.telezing.com" )
        {
            return "https://local.telezing.com/recruitment/";
        }

        if ( host === "staging.recruitment.biozing.com" )
        {
            return "https://staging.recruitment.biozing.com/";
        }

        if ( host === "recruitment.denariusmedia.com.au" )
        {
            return "https://recruitment.denariusmedia.com.au/";
        }

        return "https://recruitment.biozing.com/";
    }

    UploadDocumentUrl = () =>
    {
        return this.API_BASE + "upload/document";
    }

    UploadImageUrl = () =>
    {
        return this.API_BASE + "upload/image";
    }


    UploadVideoUrl = () =>
    {
        return this.API_BASE + "upload/video";
    }

    ImageSeachUrl = () =>
    {
        return this.API_BASE + "search/image";
    }

    UserTokenUrl = () =>
    {
        return this.API_BASE + "user/token";
    }


    SaveApplicationUrl = () =>
    {
        // add id of application to update
        return this.API_BASE + "user/application/";
    }

    PostApplicationUrl = () =>
    {
        // add id of application to post
        return this.API_BASE + "user/application/";
    }

    ListApplicationsUrl = () => {
        return this.API_BASE + "user/applications";
    }

    ListShortlistedSubmissionsUrl = () => {
        return this.API_BASE + "user/shortlisted";
    }

    LoadShortlistedSubmissionUrl = () => {
        // add id of submission
        return this.API_BASE + "user/shortlist/";
    }

    ListApplicationsSummaryUrl = () => {
        return this.API_BASE + "user/applications/summary";
    }

    LoadApplicationUrl = () => {
        // add id of application to load
        return this.API_BASE + "user/application/";
    }

    ListSubmissionsUrl = () => {
        // add link of position to get submission for
        return this.API_BASE + "user/submissions/";
    }

    LoadPublicApplicationUrl = () => {
        // add id of application to load
        return this.API_BASE + "public/application/";
    }

    DeleteSubmissionNotificationsUrl = () => {
        return this.API_BASE + "user/notification/"
    }


    UserInitRecoverAccountUrl = () => {
        return this.API_BASE + "user/recover"
    }

    AccountResetPasswordUrl = () => {
        return this.API_BASE + "account/recover"
    }
}
