
// keeps a list of a summary of applications that have been posted and active, to fill dashboard
// a tree of clients and their positions

export class ApplicationStatus {

    //status of an individual application

    static UNREAD           = 0;
    static DECLINED         = 1;
    static INTERVIEWING     = 2;
    static SHORTLISTED      = 3;

    static CLIENT_DECLINED  = 4;
    static CLIENT_ACCEPTED  = 5;

    static LAST_STATUS = 5;

    static IsValid = ( status ) => {

        if (  isNaN( status ) ) return false;

        return (status >= 0 && status <= ApplicationStatus.LAST_STATUS);
    }
}


export default class ApplicationsSummary {

    Clients = [];

    // instance of ClientSummary
    // add new client if it doesn't exist
    // returns client
    AddClient = ( client ) => {

        if ( ! client )
        {
            return new ClientSummary();
        }

        for ( var i = 0; i < this.Clients.length; i++ )
        {
            let item = this.Clients[i];
            if  (item.Name === client.Name )
            {
                //client already exists
                return item;
            }
        }

        this.Clients.push( client );
        return client;
    }


}

export class ClientSummary {

    Name = "";

    // array of PositionSummary
    Positions = [];


    TotalUnread = () => {

        let count = 0;
        for ( var i = 0; i < this.Positions.length; i++ )
        {
            count += this.Positions[i].Unread;
        }
        return count;
    }

    static Copy = ( src ) => {

        if ( ! src ) return src;

         // can't do shallow copy
         let dest = new ClientSummary();
         dest.Name = src.Name;
         if ( src.Positions )
         {
            for ( var i = 0; i < src.Positions.length; i++ )
            {
                dest.Positions.push( PositionSummary.Copy( src.Positions[i] ) );
            }
        }

        return dest;
    }

    // instance of PositionSummary
    // add new position if it doesn't exist
    // returns position
    AddPosition = ( position ) => {
        if ( ! position )
        {
            return new PositionSummary();
        }

        for ( var i = 0; i < this.Positions.length; i++ )
        {
            let item = this.Positions[i];
            if  (item.PositionID === position.PositionID )
            {
                // position already exists
                return position;
            }
        }


        this.Positions.push( position );
        return position;
    }
}


export class PositionSummary {

    ID = 0;
    PositionID = "";
    PostLink = "";
    Title = "";
    PostedDate = "";
    Active = true;
    Status = 0;

    // status count
    Unread = 0;
    Declined = 0;
    Interviewing = 0;
    Shortlisted = 0;

    DisplayTitle = () => {
        return this.PositionID + " - " + this.Title;
    }

    static Copy = ( src ) => {

        if ( ! src ) return src;

         // can do shallow copy
         let dest = Object.assign( new PositionSummary(), src );

        return dest;
    }
}