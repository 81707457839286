import { store } from '../../index';
import Globals from './Globals';


export default class User
{
    Name = "";
    Email = "";
    EmailVerified = false;
    IsAnonymous = true;
    Identifier = "";


   // LoggedInToFirebase = false;
   // LoggedInToMeeting = false;

    UserToken = "";
    FirebaseToken = "";
    FirebaseUid = "";

    static CurrentUser = () => {

        let currentState = store.getState();
        if ( currentState.user.user )
        {
            return currentState.user.user;
        }
        return new User();
    }

    LoggedIn = () => {

        if ( this.UserToken && this.UserToken.length > 0 )
        {
            return true;
        }
        return false;
    }

    static SaveToken = ( token, permanent ) => {

        // Globals.Storage.StoreSession( Globals.TOKEN_STORAGE_KEY, token )

        if ( permanent )
        {
            Globals.Storage.StoreLocal( Globals.TOKEN_STORAGE_KEY, token )
        }
        else
        {
            Globals.Storage.RemoveLocal( Globals.TOKEN_STORAGE_KEY )
        }
    }

    static LoadSavedToken = () => {

        /*
        let token = Globals.Storage.LoadSession( Globals.TOKEN_STORAGE_KEY );
        if ( token && token.length > 0 )
        {
            return token;
        }
        */
        let token = Globals.Storage.LoadLocal( Globals.TOKEN_STORAGE_KEY );
        if ( token && token.length > 0 )
        {
            return token;
        }
        return "";
    }

    static RemoveTokens = () => {

        Globals.Storage.RemoveLocal( Globals.TOKEN_STORAGE_KEY );
        Globals.Storage.RemoveSession( Globals.TOKEN_STORAGE_KEY );
    }

    /*
    SignedInDetails = () => {
        let userDetails = "";
        if ( this.IsAnonymous )
        {
            userDetails = "Signed in anonymously";
        }
        else
        {
            let detail = "";
            if ( Utils.HasString( this.Email ) )
            {
                detail = this.Email;
            }
            else
            {
                if ( Utils.HasString( this.Name ) )
                {
                    detail = this.Name;
                }
            }
            if ( detail.length === 0 )
            {
                // shouldn't happen, but just in case
                userDetails = "Signed in anonymously";
            }
            else
            {
                userDetails = "Signed in as " + detail;
            }
        }
        return userDetails;
    }
    */
}
