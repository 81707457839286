import React from 'react';
import AuthStatus from '../classes/AuthStatus';
import LogInForm from './LogInForm';

export default class HomePage extends React.Component {


    AuthStateChange = (status) => {
        if (status === AuthStatus.SIGNED_IN )
        {
            if ( this.props.callback && this.props.callback.AuthStatusChanged )
            {
                this.props.callback.AuthStatusChanged();
            }
        }
    }

    // data return by API log in
    UserLoggedIn = ( data, keepLoggedIn ) => {
        if ( this.props.callback && this.props.callback.UserLoggedIn )
        {
            this.props.callback.UserLoggedIn( data, keepLoggedIn );
        }
    }

    render() {

        return (

            <div className="login_content">

                <div className="login_logo"></div>
                <LogInForm callback={this} mode={LogInForm.LogInModeFull} showCreateAccount={this.props.showCreateAccount} />
            </div>
        )

    }
}