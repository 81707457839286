import React from 'react';

export default class Span extends React.Component {
  constructor(props) {
    super(props);

    this.state = { width: this.props.width || 0 };
    }

    render() {
        let style = { paddingLeft: this.state.width + "px" };
        return <span style={style}></span>
    }
}