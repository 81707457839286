import { BroadcastChannel } from 'broadcast-channel';

export default class Broadcast {

    static SUBMISSION_STATUS_UPDATED = "SUBMISSION_UPDATED";
    static SUBMISSION_INTERVIEW_UPDATED = "SUBMISSION_INTERVIEW_UPDATED";
    static APPLICATION_PUBLISHED = "APPLICATION_PUBLISHED";
    static APPLICATION_SAVED = "APPLICATION_SAVED";

    channel = new BroadcastChannel('recruitment');

    Channel = () => {

        return this.channel;
    }
    // this.channel.addEventListener('message', handler);

    PostMessage = ( msg ) => {

        this.channel.postMessage( msg );
    }

}