
export default class System
{
    android = false;
    ios = false;
    osVersion = "";
    osMajorVersion = 0;
    osMinorVersion = 0;
    osBuildVersion = 0;
    mobile = false;
    tablet = false;
    browser = "";
    browserVersion = "";
    browserMajorVersion = 0;
    webkit = false;
    phone = false;

    constructor()
    {
        var ua = navigator.userAgent.toLowerCase();
        // alert( ua );
        if (ua.indexOf("android") > -1)
        {
            this.android = true;
            var uaindex  = ua.indexOf( 'android ' );
            if ( uaindex > -1 && ua.length > uaindex + 10)
            {
                this.osVersion = this.calcVersion(ua.substr(uaindex+8));
            }
            // treating android tablets as phones
            this.phone = true;
        }

        if ((ua.indexOf("ipod") > -1) || (ua.indexOf("iphone") > -1) || (ua.indexOf("ipad") > -1))
        {
            this.ios = true;
            this.tablet = (ua.indexOf("ipad") > -1);
            uaindex = ua.indexOf( 'os ' );
            if ( uaindex > -1 && ua.length > uaindex + 5)
            {
                 // this.osVersion = ua.substr( uaindex + 3, 3 ).replace( '_', '.' );
                 this.osVersion =  this.calcVersion( ua.substr(uaindex + 3));
            }
            this.phone = ! this.tablet;
        }
        var rv = this.getInternetExplorerVersion();
        if ( rv > -1 )
        {
            this.browserVersion = rv;
            this.browser = "IE";
            this.browserMajorVersion = parseInt( this.browserVersion, 10 );
        }
        this.webkit = (ua.indexOf('applewebkit') > -1);

        this.mobile = (this.android || this.ios);
        this.makeVersions();
    };

    getInternetExplorerVersion = function()
    // Returns the version of Internet Explorer or a -1
    // (indicating the use of another browser).
    {
      var rv = -1; // Return value assumes failure.

      if( navigator.appName === "Netscape" )
      {
          var ua = navigator.userAgent.toLowerCase();
          if ( ua.indexOf( 'trident') > - 1 )
          {
            var re  = new RegExp("rv:([0-9]{1,}[.0-9]{0,})");
            if (re.exec(ua) != null)
                rv = parseFloat( RegExp.$1 );
          }
        return rv;
      }
      if (navigator.appName === 'Microsoft Internet Explorer')
      {
        ua = navigator.userAgent;
        re  = new RegExp("MSIE ([0-9]{1,}[.0-9]{0,})");
        if (re.exec(ua) != null)
          rv = parseFloat( RegExp.$1 );
      }
      return rv;
    }

    calcVersion = function( str )
    {
        var pos = 0;
        var res = "";
        while ( pos < 10 && pos < str.length && str.charAt(pos) !== ' ' &&  str.charAt(pos) !== ';' )
        {
            res = res + str.charAt(pos);
            pos++;
        }
        return res.replace( '_', '.' );
    };



    makeVersions = function()
    {
        if ( ! this.osVersion || this.osVersion.length === 0 )
        {
            return;
        }
        var parts = this.osVersion.split(".");
        if ( parts.length > 0 )
        {
           this.osMajorVersion = parts[0];
        }
        if ( parts.length > 1 )
        {
           this.osMinorVersion = parts[1];
        }
        if ( parts.length > 2 )
        {
           this.osBuildVersion = parts[2];
        }
    };

    AtLeastIOS7 = function()
    {
        return (this.ios && (this.osMajorVersion >= 7) );
    };

}
