import rootReducer from '../reducers';
import {createStore} from 'redux';
import initSubscriber from 'redux-subscriber';

export default (initialState) => {
  // return createStore(rootReducer, initialState,   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

  const store = createStore(rootReducer, initialState,   window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

  initSubscriber(store);
  return store;
};
