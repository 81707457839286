import React from 'react';


export default class AppHeaderButton extends React.Component {


    clicked = () => {
        if ( this.props.clicked )
        {
            this.props.clicked();
        }
    }

    render() {

        return <div className="header_button" onClick={this.clicked}>{this.props.title}</div>

    }

}