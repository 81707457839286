import React from 'react';
import Select from 'react-select';
import Application from '../classes/Application';
import ApplicationDisplayFull from './ApplicationDisplayFull';

export default class ApplicationPicker extends React.Component
{
    searchActiveInputRef = null;

    clientRef = null;
    positionRef = null;

    clients = [
        { value: "c1", label: "Pepsi Australia" },
        { value: "c2", label: "McDonalds" },
        { value: "c3", label: "Hungry Jacks" },
        { value: "c4", label: "Pizza Hut" }
     ]

     positions = {
         "c1": [
            { value: "PA3456", label: "PA3456 - Dishwasher" },
            { value: "PA1267", label: "PA1267 - PartTime Cook" },
            { value: "PA6965", label: "PA3456 - Delivery Driver" },
         ],
         "c2": [
            { value: "MC436",   label: "MC436 - Order taker" },
            { value: "MC-1267", label: "MC-1267 - Burger maker" },
            { value: "MC65",    label: "MC65 - Cleaner" },
         ],
         "c3": [],
         "c4": []
     }




    constructor( props )
    {
        super( props );

        this.state = { isLoading: false, clients: this.clients, selectedClient: null, positions: null, selectedApplication: null }

        this.searchActiveInputRef = React.createRef();
        this.clientRef = React.createRef();
        this.positionRef = React.createRef();

    }

    makeApplicationsList = ( data ) => {

        if ( ! data || data.length === 0 )
        {
            return;
        }

        let clientNames = [];
        for( var i = 0; i < data.length; i++ )
        {
            let client = data[i].client;

            if ( clientNames.indexOf( client ) === -1 )
            {
                clientNames.push( client );
            }
        }

        this.clients = [];
        this.positions = [];
        let addDummyData = true;

        // get all client names
        for ( i = 0; i< clientNames.length; i++ )
        {
            this.clients.push( { "value": "c" + i, "label": clientNames[i] })
            this.positions["c"+i] = [];
        }

        for ( i = 0; i < data.length; i++ )
        {
            let app = data[i];
            for ( var j = 0; j< this.clients.length; j++ )
            {
                let cl = this.clients[j];
                if ( app.client === cl.label )
                {
                    this.positions[cl.value].push( { value: app.position_id, label: app.position_id + " - " + app.title, "id": app.id, details: app } );
                    break;
                }
            }
        }

        if ( addDummyData )
        {
            this.clients.push( { value: "d1", label: "Pepsi Australia" } );
            this.clients.push( { value: "d2", label: "McDonalds" } );
            this.clients.push( { value: "d3", label: "Hungry Jacks" } );
            this.clients.push( { value: "d4", label: "Pizza Hut" } );

            this.positions["d1"] = [
                { value: "PA3456", label: "PA3456 - Dishwasher" },
                { value: "PA1267", label: "PA1267 - PartTime Cook" },
                { value: "PA6965", label: "PA3456 - Delivery Driver" },
             ];

             this.positions["d2"] =[
                { value: "MC436",   label: "MC436 - Order taker" },
                { value: "MC-1267", label: "MC-1267 - Burger maker" },
                { value: "MC65",    label: "MC65 - Cleaner" },
             ];

             this.positions["d3"] = [];
             this.positions["d4"] = [];
        }
    }

    componentDidUpdate( prevProps )
    {
        if ( prevProps.applicationsList !== this.props.applicationsList )
        {
        this.makeApplicationsList( this.props.applicationsList );
        if ( this.state.clients !== this.clients )
        {
            this.setState( { clients: this.clients, positions: this.positions } );
        }
    }
    }

      clientPicked = (item) => {
        if ( item === null )
        {
            this.setState( { selectedClient: null, positions: null } )
            return;
        }
        this.setState( { selectedClient: item.value, positions: this.positions[item.value], selectedApplication: null } );
        this.clearPosition();
    }

    ApplicationMadeActive = ( application, active ) => {

        let app = Application.Copy( application );
        if ( active )
        {
            app.Active = true;
            if ( app.Link && app.Link.length > 0 )
            {
                app.Status = "Posted";
            }
            else
            {
                app.Status = "Not posted";
            }
        }
        else
        {
            app.Active = false;
            app.Status = "Archived";

        }

        if ( this.positions[this.state.selectedClient] )
        {
            for ( let i = 0; i< this.positions[this.state.selectedClient].length; i++ )
            {
                if ( this.positions[this.state.selectedClient][i].id === app.ID )
                {
                    this.positions[this.state.selectedClient][i].details.archived = ! app.Active;
                    this.setState( { positions: this.positions[this.state.selectedClient] } );
                    break;
                }
            }
        }

        this.setState( { selectedApplication: app } );
    }

    ApplicationPosted = ( application, post_link ) => {

        let app = Application.Copy( application );
        app.Link = post_link;
        app.Status = "Posted";

        if ( this.positions[this.state.selectedClient] )
        {
            for ( let i = 0; i< this.positions[this.state.selectedClient].length; i++ )
            {
                if ( this.positions[this.state.selectedClient][i].id === app.ID )
                {
                    this.positions[this.state.selectedClient][i].details.post_link = post_link;
                    this.setState( { positions: this.positions[this.state.selectedClient] } );
                    break;
                }
            }
        }

        this.setState( { selectedApplication: app } );
    }


    positionPicked = (item ) => {
        if ( item === null )
        {
            this.setState( { selectedApplication : null })
            return;
        }


        let application = new Application();
        application.PositionID = item.value;
        application.ID = item.id;
        application.Title = item.label;
        application.Status = "Not posted";
        if ( item.details )
        {
            application.Link = item.details.post_link;
        }
        if ( application.Link && application.Link.length > 0 )
        {
            application.Status = "Posted";
        }

        if ( item.details )
        {
            if ( item.details.archived === 1 )
            {
                application.Status = "Archived";
            }
        }

        let total = Math.round(Math.random() * 100) + 20;

        let shortlisted = Math.round(Math.random() * 4 );

        let maxInterviewing = (total-shortlisted);
        if  (maxInterviewing > 8 ) maxInterviewing = 8;
        let interviewing = Math.round(Math.random() * maxInterviewing );


        let archived = Math.round(Math.random() * (total - shortlisted - interviewing) )



        application.Submissions = total;
        application.Declined = archived;
        application.Shortlisted = shortlisted;
        application.Interviewing = interviewing;



        this.setState( { selectedApplication: application } );

    }


    searchActiveChanged = () => {

        if ( this.props.callback && this.props.callback.SearchActiveChanged )
        {
            this.props.callback.SearchActiveChanged ( ! this.searchActiveInputRef.current.checked );
            this.setState( { isLoading: false, clients: [], selectedClient: null, positions: [], selectedApplication: null } );
        }

       this.clearClient();
       this.clearPosition();

    }

    clearClient = () => {
        try {
            this.clientRef.current.state.value = null;

        } catch ( e ) { console.log( e ) }
    }

    clearPosition = () => {
        try {
            this.positionRef.current.state.value = null;

        } catch ( e ) { console.log( e ) }
    }

    render() {


        let positionsPlaceholder = "Position...";
        if ( this.state.selectedClient === null )
        {
            positionsPlaceholder = "Please select a client first"
        }


        return (


            <div className="applications_holder">
                <div className="applications_picker_title">View Application</div>
                <div className="application_picker">

                    <Select ref={this.clientRef} className="app_picker_dropdown client"
                                instanceId="clientSelect"
                            classNamePrefix="select"
                            defaultValue={null}
                            isDisabled={false}
                            isLoading={this.state.isLoading}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            name="client"
                            options={this.state.clients}
                            placeholder="Select client..."
                            onChange={this.clientPicked} />

                            <Select ref={this.positionRef} className="app_picker_dropdown position"
                            classNamePrefix="select"
                            defaultValue={null}
                            isDisabled={false}
                            isLoading={this.state.isLoading}
                            isClearable={true}
                            isRtl={false}
                            isSearchable={true}
                            name="position"
                            noOptionsMessage={() => { return "No positions found"}}
                            options={this.state.positions}
                            placeholder={positionsPlaceholder}
                            onChange={this.positionPicked}  />

                            <label><input type="checkbox" onChange={this.searchActiveChanged} ref={this.searchActiveInputRef} />Search inactive applications</label>

                </div>
                <ApplicationDisplayFull application={this.state.selectedApplication} callback={this}/>
            </div>
        )
    }
}