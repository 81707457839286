import React from 'react';
import Globals from '../classes/Globals';
import Utils from '../classes/Utils';
import ApplictionsSummaryDisplay from '../components/ApplicationsSummaryDisplay';
import Spacer from '../ui/Spacer';
import ApplicationsList from './ApplicationsList';
import CreateInterviewForm from './CreateInterviewForm';
import LatestSubmissions from './LatestSubmissions';
import ShortlistedSubmissionsDisplay from './ShortlistedSubmissionsDisplay';







export default class Dashboard extends React.Component
{

    constructor( props )
    {
        super( props );
        this.state = {}
    }

    componentDidMount() {

        if ( Utils.DevMode() )
        {
            if ( Globals.FirebaseApp )
            {
                 Globals.FirebaseInstance.InitPushMessaging();
            }
        }
    }


    createApplication = () => {

            let url = '../application';
            if ( Utils.DevMode() )
            {
                window.open( "http://localhost:3001", "_blank" );
            }
            else
            {
                window.open( url, "_blank" );
            }
        }



    render() {

        let sub = 63;

        return (

            <div className="dashboard">
                <div className="dashboard_top_buttons">
                    <div className="dashboard_title">Recruiter Dashboard</div>
                    <div className="action_button dashboard_button" onClick={this.createApplication}>Create a Position</div>
                </div>
                <Spacer height={10} />
                <ShortlistedSubmissionsDisplay />
                <Spacer height={20} />
                <ApplictionsSummaryDisplay />
                {false && <LatestSubmissions />}
                {false && <Spacer height={20} />}
                {false && <ApplicationsList  />}
                <Spacer height={20} />

                {false && <CreateInterviewForm submission={sub} />}
            </div>

        )
    }
}