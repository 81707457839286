import React from 'react';
import {
    BrowserRouter as Router,
    Switch
} from "react-router-dom";
import Dashboard from './Dashboard';


export default class SignInPage extends React.Component {



    render( ) {

        return (
            <Router>
            <Switch>
                <Dashboard  callback={this.props.callback}/>
            </Switch>
        </Router>
        )
    }
}