import React from 'react';
import Submission from '../classes/Submission';
import CopyToClipboardButton from '../ui/CopyToClipboardButton';
import SubmissionStatusSlug from './SubmissionStatusSlug';


export default class CandidateDetailsDisplay extends React.Component {


    constructor( props )
    {
        super( props );

        let sub = Object.assign( new Submission(), this.props.submission );
        this.state = ( { submission: sub } );
    }

    componentDidMount()
    {
    }

    componentWillUnmount()
    {
    }

    componentDidUpdate( prevProps ) {

        if ( this.props.submission && this.state.submission.ID !== this.props.submission.ID )
        {
            let s = Object.assign( new Submission(), this.props.submission );
            this.setState( { submission: s } );
        }
    }

    submissionUpdated = () => {

//        let sub = Globals.Submission;

  //      this.setState( { submission: sub } );

    }

    render() {

// console.log( "state", this.state )

        let phone = this.state.submission.CandidatePhone;
        let phoneLink = "";
        if ( phone && phone.length > 0 )
        {
            phoneLink = "tel:" + phone;
        }
        else
        {
            phone = "";
        }

        let email = this.state.submission.CandidateEmail;
        let emailLink = "";
        if ( email && email.length > 0 )
        {
            emailLink = "mailto:" + email;
        }
        else
        {
            email = "";
        }

        return (
            <div className="candidate_details_holder">
                <div className="panel_instructions_shadow"></div>
                <div className="candidate_details">
                    <table>
                        <tbody>
                            <tr>
                                <td className="left">Name:</td>
                                <td>{this.state.submission.CandidateName}&nbsp;&nbsp;<CopyToClipboardButton text={this.state.submission.CandidateName} copiedMsg="Name copied" /></td>
                            </tr>
                            <tr>
                                <td className="left">Email:</td>
                                <td>{email.length > 0 && <><a href={emailLink}>{email}</a>&nbsp;&nbsp;<CopyToClipboardButton text={email} copiedMsg="Address copied" /></>}</td>
                            </tr>
                            <tr>
                                <td className="left">Phone:</td>
                                <td>{phone.length > 0 && <><a href={phoneLink}>{phone}</a>&nbsp;&nbsp;<CopyToClipboardButton text={phone} copiedMsg="Number copied" /></>}</td>
                            </tr>
                            {false && <tr>
                                <td className="left">Status:</td>
                                <td><SubmissionStatusSlug count={-1} type={this.state.submission.Status} /></td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }

}