import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ApplicationStatus } from '../classes/ApplicationsSummary';
import Spacer from '../ui/Spacer';
import SubmissionStatusSlug from './SubmissionStatusSlug';



export default class ChangeStatusTypeMenu extends React.Component{




    closeStatusTypeMenu = () => {

        if ( this.props.callback && this.props.callback.CloseStatusTypeMenu )
        {
            this.props.callback.CloseStatusTypeMenu();
        }
    }





    componentDidMount() {
        window.addEventListener('keydown', this.handleKeyDown, false );
    }

    handleKeyDown = ( event ) => {
        if ( event && event.keyCode === 27 )
        {
            this.closeStatusTypeMenu();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown',  this.handleKeyDown, false );
    }


    statusPicked = ( type ) => {
       // console.log( type, this.props.callback.StatusChangeTypePicked );
        if ( this.props.callback && this.props.callback.StatusChangeTypePicked )
        {
            this.props.callback.StatusChangeTypePicked(type);
        }
        this.closeStatusTypeMenu();
    }

    showAll = () => {
        this.statusPicked( -1 );
    }

    render() {

        let spacer = <Spacer height={10} />;

        return (
            <>
            <div className="position_type_dropdown_mask" onClick={this.closeStatusTypeMenu}></div>

            <div className="position_type_dropdown status_drop_down">
                <div className="position_type_dropdown_title"  onClick={this.closeStatusTypeMenu}><div className="position_type_dropdown_close"><FontAwesomeIcon icon={faTimes} /></div></div>
                <div className="status_drop_down_items">
                    <SubmissionStatusSlug type={ApplicationStatus.UNREAD} size="large"  count={-1} clickHandler={() => this.statusPicked(ApplicationStatus.UNREAD)}  />
                    {spacer}
                    <SubmissionStatusSlug type={ApplicationStatus.INTERVIEWING} size="large" count={-1} clickHandler={() => this.statusPicked(ApplicationStatus.INTERVIEWING)} />
                    {spacer}
                    <SubmissionStatusSlug type={ApplicationStatus.SHORTLISTED} size="large" count={-1} clickHandler={() => this.statusPicked(ApplicationStatus.SHORTLISTED)}   />
                    {spacer}
                    <SubmissionStatusSlug type={ApplicationStatus.DECLINED} size="large" count={-1} clickHandler={() => this.statusPicked(ApplicationStatus.DECLINED)}  />
                    {spacer}
                    <SubmissionStatusSlug type={ApplicationStatus.CLIENT_ACCEPTED} size="large" count={-1} clickHandler={() => this.statusPicked(ApplicationStatus.CLIENT_ACCEPTED)}  />
                    {spacer}
                    <div onClick={this.showAll} className="status_type_dropdown_item">Show all</div>
                </div>
               </div>
            </>
        )
    }
}