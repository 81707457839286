import React from 'react';
import superagent from 'superagent';
import Urls from '../classes/Urls';
import User from '../classes/User';
import IOStatus from '../classes/IOStatus';
import Submission from '../classes/Submission';
import CandidateDetailsDisplay from './CandidateDetailsDisplay';
import TimezoneSelect from 'react-timezone-select'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export default class CreateInterviewForm extends React.Component
{

    dialog = null;

    constructor( props )
    {
        super( props );

        this.state = { mounted: false, submission: null, selectedDate: null }
        this.dialog = React.createRef();
    }

    componentDidMount() {
        setTimeout( () => {
        this.setState( { mounted: true } )
        }, 0 );
        // this.dialog.current.addClass('opacity1');
        // console.log( this.props.submission );
        this.loadSubmission();
    }


    loadSubmission = async () => {

       let [status, response] = await CreateInterviewForm.LoadSaved( this.props.submission );
     //  console.log( response );
       if  ( status === IOStatus.OK && response.successful )
       {
           let data = response.submission;
           let sub = new Submission();
           sub.ID = data.id;
           sub.CandidateEmail = data.applicant_email;
           sub.CandidatePhone = data.applicant_phone;
           sub.DateSubmitted = data.date_submitted;
           sub.InterviewDate = data.interview_date;
           sub.InterviewStatus = data.interview_status;
           sub.CandidateName = data.applicant_name;


           this.setState( {submission: sub } );
       }
    }

    static LoadSaved = (id) => {


        return new Promise( ( resolve ) => {

            let url = new Urls().LoadSubmissionUrl() + id;

            let user = User.CurrentUser();

            let params = {
                        }

            superagent.get( url )
            .set('Accept', 'application/json')
            .set('Content-Type', 'application/json')
            .set('x-requested-with', 'XMLHttpRequest')
            .send( params )
            .auth(user.UserToken, { type: 'bearer' })
            .end((error, response) => {

                if ( error )
                {
                 //   console.log( error, response )
                    if ( response && response.statusCode )
                    {
                        resolve([response.statusCode, null])
                    }
                    else
                    {
                        resolve( [IOStatus.SERVER_ERROR, null] );
                    }
                    return;
                }

                if ( ! response )
                {
                    resolve(  [IOStatus.SERVER_ERROR, null] );
                    return;
                }

                resolve( [IOStatus.OK, response.body] );
            });
        });

    }

    timezonePicked = ( tz ) => {
       // console.log( tz );
    }

    handleDateSelect = (d) => {
       // console.log( "handleDateSelect", d );
    }

    handleDateChange = (d) => {
      //  console.log( "handleDateChange", d );
        this.setState( { selectedDate: d } );
    }

    createInvite = () => {

      //  console.log( "create" );

    }

    render() {

      //  console.log( this.state.submission );

        let cls = "create_interview_dialog";
        if ( this.state.mounted )
        {
            cls += " opacity1";
        }
        return (

            <div ref={this.dialog} className={cls}>
                <div className="create_interview_mask"></div>
                <div className="create_interview_form">
                    <div className="create_interview_title">Candidate Interview</div>
                    <CandidateDetailsDisplay submission={this.state.submission} />
                    <div className="create_interview_date_picker_holder">
                        <div className="create_interview_date_holder_label">Time:</div>
                        <DatePicker
                            selected={this.state.selectedDate}
                            onSelect={this.handleDateSelect} //when day is clicked
                            onChange={this.handleDateChange} //only when value has changed
                            showTimeSelect
                            inline={false}
                            dateFormat="d MMM yyyy   h:mm aa"
                            placeholderText="Select a date/time..."
                            className="create_interview_date_picker"
                            timeIntervals={15}
                            />
                    </div>
                    <div className="create_interview_timezone_holder">
                        <div className="create_interview_timezone_holder_label">Timezone:</div>
                        <TimezoneSelect value={{value:null}} onChange={this.timezonePicked} labelStyle="abbrev"/>
                    </div>
                    <div className="create_interview_footer">
                        <div className="action_button submit" onClick={this.createInvite}>Save</div>
                        <div className="action_button quiet">Cancel</div>
                    </div>
                </div>

            </div>
        )

    }
}