
import copy from 'copy-to-clipboard';
import React from 'react';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import BioZing from '../classes/BioZing';
import IOStatus from '../classes/IOStatus';
import Urls from '../classes/Urls';
import Utils from '../classes/Utils';
import Spacer from '../ui/Spacer';
import Span from '../ui/Span';


export default class ApplicationDisplayFull extends React.Component
{

    constructor( props )
    {
        super( props );
        this.state = { isPosting: false, link: null, copyLinkTitle: "Copy Link" }
    }
    post = async () => {

        this.setState( { isPosting: true } );
        let  bz = new BioZing();
        let res = await bz.PostApplication( this.props.application.ID );
        // console.log( res )

        if ( res && res.result === IOStatus.OK && res.post_link && res.post_link.length > 0 )
        {
            //console.log( res.post_link );

            this.setState( { isPosting: false } );
            if ( this.props.callback && this.props.callback.ApplicationPosted )
            {
                this.props.callback.ApplicationPosted( this.props.application, res.post_link );
            }
            return;
        }
        Utils.Alert( "Could not post application" );
    }

    edit = () => {

        if ( this.props.application && this.props.application.ID > 0 )
        {
            let url = '../application' + "/" + this.props.application.ID;
            if ( Utils.DevMode() )
            {
            window.open( "http://localhost:3001" + "/" + this.props.application.ID, "_blank" );
            }
            else
            {
                window.open( url, "_blank" );
            }
        }
    }

    archive = async () => {

        let bz = new BioZing();
        await bz.Update( this.props.application.ID, { active: 0 } );
        this.madeActive( false );
    }

    unarchive = async () => {

        let bz = new BioZing();
        await bz.Update( this.props.application.ID, { active: 1 } );
        this.madeActive( true );
    }

    madeActive = ( active ) => {
        if ( this.props.callback && this.props.callback.ApplicationMadeActive )
        {
            this.props.callback.ApplicationMadeActive( this.props.application, active );
        }
    }
    view = () => {

        window.open( new Urls().ApplicationDisplayUrl() + this.props.application.Link, "_blank" );
    }

    copyToClipboard = ( txt ) =>
    {
        copy(txt, {
            debug: false,
            message: 'Press #{key} to copy',
          });
        this.setState( { copyLinkTitle: "COPIED!"} );
        setTimeout( () => {
            this.setState( { copyLinkTitle: "Copy Link"} );
        }, 1500 )
    }

    render() {


//console.log( "app", this.props.application)

        if ( ! this.props.application )
        {
            return null;
        }

        let remaining = this.props.application.Submissions - (this.props.application.Shortlisted + this.props.application.Declined + this.props.application.Interviewing);

        let hasLink = true;
        let fullLink = "";
        let link = this.props.application.Link;
        if ( ! link || link.length === 0 )
        {
            link = "Not available until posted";
            hasLink = false;
        }
        else
        {
            fullLink = new Urls().ApplicationDisplayUrl() + link;
        }

        let isActive = this.props.application.Active;

        return (

            <div className="application_full_display">
                <div className="application_full_display_title">{this.props.application.Title}</div>
                <div className="application_full_display_content">
                    <div className="application_full_display_detail">Status: {this.props.application.Status}</div>
                    <div className="application_full_display_detail">Link: {link}</div>
                    <Spacer height={5} />
                    <div className="group">
                        <div className="group_title">Submissions</div>
                        <table className="application_full_display_detail_table">
                        <tbody>
                        <tr>
                            <td>Shortlisted:</td><td className="ta_left">{this.props.application.Shortlisted}</td>
                        </tr>
                        <tr>
                            <td>Interviewing:</td><td className="ta_left">{this.props.application.Interviewing}</td>
                        </tr>
                        <tr>
                        <td>Declined:</td><td className="ta_left">{this.props.application.Declined}</td>
                        </tr>
                        <tr>
                        <td>Unread:</td><td className="ta_left">{remaining}</td>
                        </tr>
                        <tr>
                            <td >Total:</td><td className="ta_left">{this.props.application.Submissions}</td>
                        </tr>
                        </tbody>
                        </table>
                        <Spacer height={5} />
                        <div className="action_button tiny quiet" >View submissions</div><Span width={10} />
                    </div>
                    <Spacer height={30} />
                    {isActive && <span>
                                        <Span width={5} />
                                            <div className="action_button sm" onClick={this.edit}>Edit</div>
                                        <Span width={5} />
                                    </span>
                    }
                    {(isActive && ! hasLink) && <span><Span width={5} /><div className="action_button sm"  onClick={this.post}>Post</div><Span width={5} /></span>}
                    {(isActive && fullLink.length > 0) && <span><Span width={5} /><div className="action_button xxsm quiet copy_link_button" onClick={() => this.copyToClipboard(fullLink)}>{this.state.copyLinkTitle}</div><Span width={5} /></span>}
                    {(isActive && fullLink.length > 0) && <span><Span width={5} /><div className="action_button xxsm quiet" onClick={this.view}>View</div><Span width={5} /></span>}
                    {isActive &&
                            <span>
                                <Span width={5} />
                                    <div className="action_button xxsm quiet" onClick={this.archive}>Archive</div>
                                <Span width={5} />
                            </span>
                    }
                    {! isActive &&
                            <span>
                                <Span width={5} />
                                    <div className="action_button xxsm quiet" onClick={this.unarchive}>Unarchive</div>
                                <Span width={5} />
                            </span>
                    }
                </div>
                {this.state.isPosting &&
                    <div className="application_full_display_posting">
                        <div className="application_full_display_posting_content"><Loader type="Oval" color="#40a148" height={30} width={30} /><div className="application_full_display_posting_content_msg">Posting...</div></div>
                    </div>
                }
            </div>
        )
    }
}